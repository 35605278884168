import React, { CSSProperties } from 'react';

import { ContentSecondLevelPage } from '../../../generated/queries/preview-queries';
import { Image } from '../../components/0-atoms';
import { HeroButton } from '../../components/2-organisms';
import { SecondLevelPageProps } from '../../components/3-layouts';
import { getImageSrc } from '../../utils';
import { getImageStyleUrl } from './MediaImage';

export const transformSecondLevelPageFragmentToSecondLevelPageProps = (
  page?: ContentSecondLevelPage,
): SecondLevelPageProps => {
  if (!page) {
    return {};
  }

  const headerImage = page?.headerImage;

  const laptopWideStyleUrl = getImageStyleUrl(headerImage, 'laptop_wide');
  const desktopSuperWideStyleUrl = getImageStyleUrl(
    headerImage,
    'desktop_super_wide',
  );
  const mobiStyleUrl = getImageStyleUrl(headerImage, 'mobile_small');
  const isCropped =
    laptopWideStyleUrl !== null || desktopSuperWideStyleUrl !== null;

  const pictureImageStyle: CSSProperties | undefined = {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  };

  const image =
    headerImage && !isCropped ? (
      <Image
        alt={headerImage?.alt || page.title}
        filemime={headerImage?.mimeType}
        src={getImageSrc(
          headerImage,
          `${getImageStyleUrl(headerImage, 'header_image')} 2000w, 
            ${getImageStyleUrl(
              headerImage,
              'header_slider_image_responsive_',
            )} 750w`,
        )}
        sizes="(max-width: 3840px) 1920w, (max-width: 2880px) 1440w, (max-width: 2560px) 1920w, (max-width: 1440px) 1440w, (max-width: 750px) 750w, 100vw"
        className="SubpageHeader--background--img"
        fit
        loading="eager"
      />
    ) : undefined;

  const croppedPicture =
    headerImage && isCropped ? (
      <picture className="SubpageHeader--background--img">
        <source
          media="(min-width: 1441px)"
          srcSet={`${desktopSuperWideStyleUrl} 1x, ${desktopSuperWideStyleUrl} 2x`}
        />
        <source
          media="(min-width: 751px)"
          srcSet={`${laptopWideStyleUrl} 1x, ${laptopWideStyleUrl} 2x`}
        />
        <source media="(max-width: 750px)" srcSet={`${mobiStyleUrl} 1x`} />
        <img
          src={
            laptopWideStyleUrl ||
            'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
          }
          alt={headerImage?.alt || page.title}
          style={pictureImageStyle}
          loading="eager"
        />
      </picture>
    ) : undefined;

  const heroButton = page.heroButtonType ? (
    <HeroButton type={page.heroButtonType} url={page.heroButtonLink?.url} />
  ) : undefined;

  return {
    title: page.title,
    image: croppedPicture || image,
    heroButton,
    hideFundingBanner: page.hideFundingBanner,
    hideCtasHeader: page.hideCallToActionInHeader,
  };
};

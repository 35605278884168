import { graphql, useStaticQuery } from 'gatsby';

export const useIndustryTermsQuery = () =>
  useStaticQuery<IndustryTermsQuery>(graphql`
    query IndustryTerms {
      allDrupalTermIndustry {
        group(field: { langcode: SELECT }) {
          fieldValue
          nodes {
            drupalId
            remoteId
            title
          }
        }
      }
    }
  `);

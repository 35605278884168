import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataDependencies,
  useFrameworkDependencies,
} from '../../dependencies';
import { Icon } from '../../types';

export type ActionBarProps = {
  path?: string;
  remoteId?: string;
  className?: string;
};

export const ActionBar: React.FC<ActionBarProps> = ({
  path,
  remoteId,
  className,
}) => {
  const { Link, useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { t } = useTranslation();
  const { useEntityUrlsQuery } = useDataDependencies();
  const nodeId = remoteId?.split(':')[0] || undefined;

  const { data: entityUrls, isLoading } = useEntityUrlsQuery({
    language: locale,
    path,
    nodeId,
  });

  return !isLoading && entityUrls ? (
    <div className={classNames('ActionBar', className)}>
      <ul className="ActionBar--list">
        {entityUrls['edit-form'] && (
          <li role="presentation">
            <Link
              to={entityUrls['edit-form']}
              className={'ActionBar--link'}
              target="_blank"
              rel="noreferrer"
            >
              <span className="ActionBar--link--picto">
                <i className={classNames('ico', Icon.AdminEdit)} />
              </span>
              {t('Edit')}
            </Link>
          </li>
        )}
        {entityUrls['drupal:content-translation-overview'] && (
          <li role="presentation">
            <Link
              to={entityUrls['drupal:content-translation-overview']}
              className={'ActionBar--link'}
              target="_blank"
              rel="noreferrer"
            >
              <span className="ActionBar--link--picto">
                <i className={classNames('ico', Icon.AdminTranslate)} />
              </span>
              {t('Translate')}
            </Link>
          </li>
        )}
        {entityUrls['delete-form'] && (
          <li role="presentation">
            <Link
              to={entityUrls['delete-form']}
              className={'ActionBar--link'}
              target="_blank"
              rel="noreferrer"
            >
              <span className="ActionBar--link--picto">
                <i className={classNames('ico', Icon.AdminDelete)} />
              </span>
              {t('Delete')}
            </Link>
          </li>
        )}
      </ul>
    </div>
  ) : null;
};

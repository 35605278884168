import { useLocation } from '@reach/router';
import queryString from 'query-string';

import { useCurrentUserQuery } from '../../generated/queries/preview-queries';
import { PageContext, PreviewPageContext } from '../types';

export const PREVIEW_REFRESH_INTERVAL = 5000;

export const getPreviewRemoteId = ({
  location,
  pageContext,
}: {
  location: Location;
  pageContext: PreviewPageContext | PageContext;
}): string => {
  const parsedSearch = queryString.parse(location.search) as {
    id: string;
  };
  const { id } = parsedSearch;
  // Favour the query string id as it might not always be available
  // for new pages, revisions, path changes, ...
  const drupalId = id || pageContext.remoteId;
  return pageContext?.locale && pageContext?.locale !== ''
    ? `${drupalId}:${pageContext.locale}`
    : drupalId;
};

export const getPreviewRevisionId = ({
  location,
}: {
  location: Location;
}): string => {
  const parsedSearch = queryString.parse(location.search) as {
    revision: string;
  };
  const { revision } = parsedSearch;
  return revision || '0';
};

export const usePreviewQuery = () => {
  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search) as {
    preview?: string;
  };
  const { preview } = parsedQueryString;
  const isPreview = (preview && preview === '1') || false;
  const currentUser = useCurrentUserQuery({}, { enabled: isPreview });
  if (!isPreview || currentUser.isLoading || currentUser.isError) {
    return false;
  } else {
    return (
      isPreview &&
      currentUser.isSuccess &&
      currentUser?.data?.currentUser?.id &&
      // Shouldn't even reach this stage if anonymous
      // as anonymous users shouldn't be able to
      // execute arbitrary queries. Keeping this test
      // as a safeguard to prevent permissions misconfiguration as
      // there shouldn't be use cases for anonymous preview.
      currentUser?.data?.currentUser?.id !== '0'
    );
  }
};

type ContentQueryVariables = { remoteId: string };
type ContentRevisionQueryVariables = { remoteId: string; revisionId: string };
type ContentQueryOptions = {
  enabled: boolean;
  refetchInterval: false | number;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  refetchOnReconnect?: boolean;
};

export function useContent<T>(
  useContentQuery: (
    vars: ContentQueryVariables,
    opts: ContentQueryOptions,
  ) => { data?: { content?: T } },
  useContentRevisionQuery: (
    vars: ContentRevisionQueryVariables,
    opts: ContentQueryOptions,
  ) => { data?: { content?: T } },
  staticContent: T | undefined,
  pageContext: PageContext | PreviewPageContext,
) {
  const location = useLocation();
  const usePreview = usePreviewQuery() || false;
  const usePreviewRevision =
    usePreview && getPreviewRevisionId({ location }) !== '0';

  const previewRevisionContent = useContentRevisionQuery(
    {
      remoteId: getPreviewRemoteId({ location, pageContext }),
      revisionId: getPreviewRevisionId({ location }),
    },
    {
      refetchInterval: usePreview ? PREVIEW_REFRESH_INTERVAL : false,
      enabled: usePreviewRevision,
    },
  );

  const previewContent = useContentQuery(
    {
      remoteId: getPreviewRemoteId({ location, pageContext }),
    },
    {
      refetchInterval: PREVIEW_REFRESH_INTERVAL,
      // Enable the preview query only
      // when the revision query is not enabled.
      enabled: usePreview && !usePreviewRevision,
    },
  );

  return (
    previewRevisionContent.data?.content ||
    previewContent.data?.content ||
    staticContent
  );
}

import React from 'react';

import { useOverlays } from '../../hooks';
import { Overlay, transformOverlayToProps } from './Overlay';

export type OverlaysProps = {};

export const Overlays: React.FC<OverlaysProps> = () => {
  const overlays = useOverlays();

  return (
    <>
      {overlays?.map((overlay) => (
        <Overlay
          key={`overlay-${overlay.drupalId}`}
          {...transformOverlayToProps(overlay)}
        />
      ))}
    </>
  );
};

import React from 'react';

import { ParagraphTestimonialGallery } from '../../../generated/queries/preview-queries';
import { Image } from '../../components/0-atoms';
import { isPublished } from '../../utils';
import { getImageStyleUrl, transformImageFragmentToProps } from './MediaImage';

export const transformParagraphFullWidthGalleryToProps = (
  paragraph: ParagraphTestimonialGallery,
) => ({
  items:
    paragraph?.items?.filter(isPublished).map((item) => {
      const paragraphImage = item?.image;

      const image = paragraphImage ? (
        <Image
          {...transformImageFragmentToProps(paragraphImage, [
            'small',
            'medium',
            'large',
          ])}
          size="small"
          className="FullWidthGallery--item"
        />
      ) : undefined;

      return {
        image,
        url: getImageStyleUrl(paragraphImage, 'large'),
        description: item?.descriptionHtml,
      };
    }) || [],
});

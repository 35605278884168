import { graphql, useStaticQuery } from 'gatsby';

export const useLatestEventsQuery = () =>
  useStaticQuery<LatestEventsQuery>(graphql`
    fragment LatestEvent on DrupalContentEvent {
      drupalId
      title
      date
      path
      domainAccess {
        id
      }
    }
    query LatestEvents {
      it: allDrupalContentEvent(
        filter: { langcode: { eq: "it" } }
        limit: 3
        sort: { date: DESC }
      ) {
        nodes {
          ...LatestEvent
        }
      }
      en: allDrupalContentEvent(
        filter: { langcode: { eq: "en" } }
        limit: 3
        sort: { date: DESC }
      ) {
        nodes {
          ...LatestEvent
        }
      }
      fr: allDrupalContentEvent(
        filter: { langcode: { eq: "fr" } }
        limit: 3
        sort: { date: DESC }
      ) {
        nodes {
          ...LatestEvent
        }
      }
      de: allDrupalContentEvent(
        filter: { langcode: { eq: "de" } }
        limit: 3
        sort: { date: DESC }
      ) {
        nodes {
          ...LatestEvent
        }
      }
    }
  `);

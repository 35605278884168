import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';

import { transformImageFragmentToProps } from '../../fragments/mappers';
import { displayOverlay } from '../../utils';
import { Image, OverlayClose } from '../0-atoms';
import {
  OverlayCrisis,
  OverlayFloatingBox,
  OverlayFull,
  OverlayTransparent,
} from '../1-molecules';

export type OverlayProps = {
  type: 'floating' | 'full' | 'transparent' | 'crisis';
  id: string;
  interval?: number;
  title: string;
  subtitle?: string;
  text?: string;
  url?: string;
  linkLabel?: string;
  image?: string | ReactNode;
  className?: string;
  showClose: boolean;
};

export const Overlay: React.FC<OverlayProps> = ({
  type,
  id,
  interval = 0,
  className,
  ...props
}) => {
  const [modal, setModal] = useState<HTMLDivElement | null>(null);
  const [doDisplayOverlay, setDoDisplayOverlay] = useState<boolean>(false);
  const hasOverlay: boolean = displayOverlay(id, interval);
  useEffect(() => {
    setDoDisplayOverlay(hasOverlay);
  }, [hasOverlay]);
  return doDisplayOverlay ? (
    <div
      ref={(ref) => {
        setModal(ref);
      }}
      className={classNames('Modal is-open', className, {
        full: type !== 'transparent',
      })}
    >
      <div className="Modal--inner">
        <div className="Modal--body js-Closeable--body">
          {type === 'floating' && <OverlayFloatingBox id={id} {...props} />}
          {type === 'full' && <OverlayFull id={id} {...props} />}
          {type === 'crisis' && <OverlayCrisis id={id} {...props} />}
          {type === 'transparent' && <OverlayTransparent id={id} {...props} />}
        </div>
      </div>
      {modal && props.showClose && <OverlayClose id={id} modal={modal} />}
    </div>
  ) : null;
};

export const transformOverlayToProps = (
  overlay: OverlaysQuery['allOverlays']['group'][0]['nodes'][0],
): OverlayProps => {
  const overlayImage = overlay?.overlayImage;
  let type: OverlayProps['type'] = 'transparent';
  let image: ReactNode | undefined;
  let showClose: boolean = true;

  switch (overlay.overlayType) {
    default:
    case 'transparent_overlay':
      type = 'transparent';
      image = overlayImage ? (
        <Image
          className="BlockTeaser--img"
          {...transformImageFragmentToProps(overlayImage, [
            'small',
            'medium',
            'large',
          ])}
          size="medium"
          fit
        />
      ) : undefined;
      break;
    case 'floatingbox_overlay':
      type = 'floating';
      image = overlayImage ? (
        <Image
          {...transformImageFragmentToProps(overlayImage, [
            'small',
            'medium',
            'large',
          ])}
          size="large"
          className="FloatingBox--img"
        />
      ) : undefined;
      break;
    case 'full_overlay':
      type = 'full';
      image = overlayImage ? (
        <Image
          {...transformImageFragmentToProps(overlayImage, [
            'breaking_news_portrait',
          ])}
          className="FullscreenImageText--img"
          fit
        />
      ) : undefined;
      break;
    case 'crisis_overlay':
      showClose = false;
      type = 'crisis';
      image = overlayImage ? (
        <Image
          {...transformImageFragmentToProps(overlayImage, [
            'breaking_news_portrait',
          ])}
          className="FullscreenImageText--img"
          fit
        />
      ) : undefined;
      break;
  }

  return {
    id: overlay.drupalId,
    type,
    image,
    title: overlay.overlayTitle || '',
    subtitle: overlay.overlaySubTitle,
    text: overlay.overlayText,
    interval: overlay.overlayInterval,
    url: overlay.overlayMoreLink?.url || '',
    linkLabel: overlay.overlayMoreText,
    showClose,
  };
};

import { APP_CONFIG } from '../constants';
import { Domain } from '../types';

export const getDomainUrl = ({
  absoluteUrl,
  domain,
}: {
  absoluteUrl: string;
  domain?: Domain;
}) => {
  if (domain?.pathPrefix && domain?.pathPrefix !== '') {
    const baseUrlWithDomainPrefix = `${APP_CONFIG.drupalUrl}${domain?.pathPrefix}`;
    return absoluteUrl.replace(APP_CONFIG.drupalUrl, baseUrlWithDomainPrefix);
  }
  return absoluteUrl;
};

import { Locale } from '../../i18n';

export const getPreviousAndNextBlogPost = (
  id: number | undefined,
  locale: Locale,
  data: BlogPaginationQuery,
) => {
  let previous;
  let next;

  if (!id) {
    return { previous, next };
  }

  const blogs = data.allDrupalContentBlog.edges.filter(
    (blog) => blog.node.langcode === locale,
  );
  const actualElement = blogs.find(
    ({ node }) => parseInt(node.drupalId) === id,
  );

  if (actualElement) {
    const elementIndex = blogs.indexOf(actualElement);
    if (elementIndex > 0) {
      previous = blogs[elementIndex - 1].node;
    } else {
      previous = blogs[blogs.length - 1].node;
    }
    if (elementIndex < blogs.length - 1) {
      next = blogs[elementIndex + 1].node;
    } else {
      next = blogs[0].node;
    }
  }
  return { previous, next };
};

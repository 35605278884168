import { ParagraphFromLibrary } from '../../../generated/queries/preview-queries';
import { isPublished } from '../../utils';

export const transformParagraphFromLibraryToProps = (
  paragraph: ParagraphFromLibrary,
) => ({
  content: isPublished(paragraph?.reusableParagraph?.paragraph)
    ? paragraph?.reusableParagraph?.paragraph
    : undefined,
});

import { ContentNews } from '../../../generated/queries/preview-queries';
import { Locale } from '../../../i18n';
import { NewsPageProps } from '../../components/3-layouts';
import { formatLocalizedDate } from '../../utils';

export const transformNewsFragmentToNewsPage = (
  news: ContentNews,
): NewsPageProps => {
  return {
    title: news.title,
    lead: news.leadHtml,
    hideFundingBanner: news.hideFundingBanner,
    date: news.date
      ? formatLocalizedDate((news.langcode as Locale) || 'en')(
          new Date(news.date),
        )
      : undefined,
  };
};

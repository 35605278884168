import { Locale } from '../../i18n';
import { useDataDependencies, useFrameworkDependencies } from '../dependencies';
import { getNodesByLanguage } from '../utils';

export const useEventCategoryTerms = (locale?: Locale) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const { useEventCategoryTermsQuery } = useDataDependencies();
  const terms = useEventCategoryTermsQuery();

  return getNodesByLanguage<
    EventCategoryTermsQuery['allDrupalTermEventCategory']['group'][0]['nodes'][0]
  >(locale || currentLocale)(terms.allDrupalTermEventCategory);
};

import { Locale } from '../../i18n';
import { useDataDependencies, useFrameworkDependencies } from '../dependencies';
import { getNodesByLanguage } from '../utils';

export const useNewsTypesTerms = (locale?: Locale) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const { useNewsTypesTermsQuery } = useDataDependencies();
  const newsTerms = useNewsTypesTermsQuery();

  return getNodesByLanguage<
    NewsTypesTermsQuery['allDrupalTermNewsType']['group'][0]['nodes'][0]
  >(locale || currentLocale)(newsTerms.allDrupalTermNewsType);
};

type GroupQueryData<N> = {
  group: {
    fieldValue?: string;
    nodes: N[];
  }[];
};

export const getNodesByLanguage =
  <N>(language: string) =>
  (data?: GroupQueryData<N>): N[] | undefined =>
    data?.group.find((group) => group.fieldValue === language)?.nodes;

import {
  ContentDonationPayment,
  ParagraphDonationWidget,
} from '../../generated/queries/preview-queries';
import { Locale } from '../../i18n';
import { DOMAINS } from '../constants';
import { getLocalizedUrl } from './getLocalizedUrl';

export const getFundingWidgetPropsFromFragment = (
  page: ContentDonationPayment,
) => {
  const fundingWidgetPageContent = page?.content?.find(
    (content?: any) => content?.__typename === 'ParagraphDonationWidget',
  ) as ParagraphDonationWidget | undefined;

  return fundingWidgetPageContent
    ? {
        amounts: fundingWidgetPageContent?.amounts as number[],
        paymentUrl: getLocalizedUrl({
          to: page?.path || '',
          locale: page?.langcode as Locale,
          domain: DOMAINS[page?.domainAccess?.[0]?.id || 'paraplegie_ch'],
        }).url,
      }
    : undefined;
};

import { ParagraphConversionWidget } from '../../../generated/queries/preview-queries';

export const transformParagraphConversionWidgetToProps = (
  paragraph: ParagraphConversionWidget,
) => ({
  donationPaymentUrl: paragraph.donationPaymentLink?.url,
  donationPaymentPage: paragraph.donationPaymentPage,
  widgetType: paragraph.widgetType,
  displayMode: paragraph.displayMode,
});

import React from 'react';

import {
  ContentFirstLevelPage,
  ParagraphSlider,
  ParagraphSliderImage,
  ParagraphSliderItem,
  ParagraphTeasers,
} from '../../../generated/queries/preview-queries';
import { Image } from '../../components/0-atoms';
import { HeroButton } from '../../components/2-organisms';
import { FirstLevelPageProps } from '../../components/3-layouts';
import { getImageSrc } from '../../utils';
import { getFundingWidgetPropsFromFragment } from '../../utils/fundingWidget';
import { getImageStyleUrl } from './MediaImage';
import { transformParagraphHeaderSliderToProps } from './ParagraphSlider';
import { transformParagraphTeasersToProps } from './ParagraphTeasers';

const getTeaserPropFromFragment = (page: ContentFirstLevelPage) =>
  page?.teaserGrid
    ? transformParagraphTeasersToProps(page?.teaserGrid as ParagraphTeasers)
    : undefined;

const getSliderPropFromFragment = (page: ContentFirstLevelPage) =>
  (page?.headerSlider as ParagraphSlider | undefined)?.items
    ? {
        ...transformParagraphHeaderSliderToProps(
          page?.headerSlider as ParagraphSlider,
        ),
        heroButton: (
          <HeroButton
            type={page.heroButtonType}
            url={page.heroButtonLink?.url}
          />
        ),
      }
    : undefined;

const getFundingWidgetPropsFromPage = (page: ContentFirstLevelPage) => {
  if (!page?.donationPaymentPage) {
    return undefined;
  }
  return getFundingWidgetPropsFromFragment(page.donationPaymentPage);
};

function isImageSlide(
  slide?: ParagraphSliderImage | ParagraphSliderItem | null,
): slide is ParagraphSliderImage {
  return slide?.__typename === 'ParagraphSliderImage';
}

const getFirstSliderImageFromFragment = (
  page: ContentFirstLevelPage,
): ParagraphSliderImage | undefined => {
  const slide = (page?.headerSlider as ParagraphSlider | undefined)?.items?.[0];
  return isImageSlide(slide) ? slide : undefined;
};

export const transformFirstLevelPageFragmentToFirstLevelPageProps = (
  page: ContentFirstLevelPage,
): FirstLevelPageProps => {
  if (!page) {
    return {};
  }
  const teaser = getTeaserPropFromFragment(page);
  const slider = getSliderPropFromFragment(page);
  const fundingWidget = getFundingWidgetPropsFromPage(page);
  const firstImageSlide = getFirstSliderImageFromFragment(page);
  const firstSliderImage = firstImageSlide?.image;
  const title = firstImageSlide?.imageTitle;

  const desktopWideStyleUrl = getImageStyleUrl(
    firstSliderImage,
    'desktop_wide',
  );
  const desktopStdStyleUrl = getImageStyleUrl(
    firstSliderImage,
    'desktop_standard',
  );
  const isCropped = desktopWideStyleUrl !== null || desktopStdStyleUrl !== null;

  const image = firstSliderImage ? (
    <Image
      alt={firstSliderImage?.alt || title}
      filemime={firstSliderImage?.mimeType}
      src={
        isCropped
          ? getImageSrc(
              firstSliderImage,
              `${desktopWideStyleUrl} 2000w,
            ${getImageStyleUrl(firstSliderImage, 'desktop_standard')} 1440w,
            ${getImageStyleUrl(firstSliderImage, 'mobile')} 750w`,
            )
          : getImageSrc(
              firstSliderImage,
              page.hideFundingBanner
                ? `${getImageStyleUrl(firstSliderImage, 'header_image')} 2000w, 
                 ${getImageStyleUrl(
                   firstSliderImage,
                   'header_slider_image_responsive_',
                 )} 750w`
                : `${getImageStyleUrl(firstSliderImage, 'header_image')} 2000w, 
                 ${getImageStyleUrl(
                   firstSliderImage,
                   'header_slider_image_1280_x_540_crop',
                 )} 750w`,
            )
      }
      className="HeaderHero--background--img"
      sizes="(max-width: 750px) 50vw, 100vw"
      fit
      loading="eager"
    />
  ) : undefined;

  return {
    title,
    description: (page.headerSlider as ParagraphSlider | undefined)?.items?.[0]
      ?.descriptionHtml,
    image,
    slider,
    teaser,
    fundingWidget,
    hideFundingBanner: page.hideFundingBanner,
    showMembershipWidget: page.showMembershipBox,
    showGiftMembershipWidget: page.showGiftMembershipBox,
    showDonationWidget: page.showDonationWidget,
    hideCtasHeader: page.hideCallToActionInHeader,
  };
};

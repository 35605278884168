import { graphql, useStaticQuery } from 'gatsby';

export const useCategoryTermsQuery = () =>
  useStaticQuery<CategoryTermsQuery>(graphql`
    query CategoryTerms {
      allDrupalTermCategory {
        group(field: { langcode: SELECT }) {
          fieldValue
          nodes {
            drupalId
            remoteId
            title
          }
        }
      }
    }
  `);

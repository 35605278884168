import classNames from 'classnames';
import React from 'react';

import { ConditionalWrapper } from '../../utils/conditionalContainer';
import { processWysiwyg } from '../../utils/processWysiwyg';
import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphCopyTextProps = {
  text?: string;
  className?: string;
  hasBackground?: boolean;
  children?: React.ReactNode;
};

export const ParagraphCopyText: React.FC<ParagraphCopyTextProps> = ({
  text = '',
  className,
  children,
  hasBackground,
}) => {
  return text ? (
    <ConditionalWrapper
      condition={hasBackground}
      wrapper={(children: any) => (
        <div className="ArticleText has-background">
          <FadeUp yGap={20}>
            <div className="hasBackground--wrapper">{children}</div>
          </FadeUp>
        </div>
      )}
    >
      <FadeUp yGap={20}>
        <div
          className={classNames('use-formatted-links', className, {
            ArticleText: !hasBackground,
          })}
          dangerouslySetInnerHTML={{ __html: processWysiwyg(text) }}
        />
      </FadeUp>
    </ConditionalWrapper>
  ) : (
    <ConditionalWrapper
      condition={hasBackground}
      wrapper={(children: any) => (
        <div className="ArticleText has-background">
          <FadeUp yGap={20}>{children}</FadeUp>
        </div>
      )}
    >
      <FadeUp yGap={20}>
        <div
          className={classNames(className, {
            ArticleText: !hasBackground,
          })}
        >
          {children}
        </div>
      </FadeUp>
    </ConditionalWrapper>
  );
};

export default ParagraphCopyText;

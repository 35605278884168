import { ParagraphTable } from '../../../generated/queries/preview-queries';
import { isPublished } from '../../utils';

export const transformParagraphKeyValueTableToProps = (
  paragraph: ParagraphTable,
) => ({
  rows:
    paragraph?.rows?.filter(isPublished).map((row) => ({
      key: row?.key || '',
      value: row?.value || '',
    })) || [],
});

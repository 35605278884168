import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from './fetcher';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Void: any;
};

export type AllContent = ContentBlog | ContentBreakingNews | ContentCompetences | ContentDoctor | ContentEvent | ContentFirstLevelPage | ContentHistory | ContentNews | ContentSecondLevelPage | ContentThankYouPage | ContentThirdLevelPage | ContentZsmForm;

export type AllParagraphs = ParagraphAccordion | ParagraphAccordionDownloadItem | ParagraphAccordionLinkItem | ParagraphAccordionSimpleItem | ParagraphAnchorTag | ParagraphBlockTeaser | ParagraphBlockTeaserList | ParagraphCallToAction | ParagraphCompetencesAccordion | ParagraphContentBox | ParagraphConversionWidget | ParagraphCopyText | ParagraphCustomHtml | ParagraphCustomMarkup | ParagraphDonationWidget | ParagraphDownloadList | ParagraphEmpty | ParagraphEventModule | ParagraphFloatingQuote | ParagraphFromLibrary | ParagraphFullWidthGalleryImage | ParagraphGallery | ParagraphGalleryImage | ParagraphHomeSliderItem | ParagraphIframe | ParagraphImage | ParagraphImageGrid | ParagraphImageTeaser | ParagraphInfoBox | ParagraphInfoBoxList | ParagraphItemList | ParagraphLinkList | ParagraphListingCompanyParaworkSearch | ParagraphListingCompanySearch | ParagraphListingDoctorSearch | ParagraphListingMedicalOfferSearch | ParagraphListingNewsroom | ParagraphLogo | ParagraphLogoBar | ParagraphMap | ParagraphMayorisPlaceholder | ParagraphOrthotecGallery | ParagraphOrthotecValueTable | ParagraphPrice | ParagraphRaiseNowForm | ParagraphRow | ParagraphSlider | ParagraphSliderImage | ParagraphStorifyMe | ParagraphStorytelling | ParagraphTable | ParagraphTableRow | ParagraphTeamList | ParagraphTeamListItemExtended | ParagraphTeamListItemSimple | ParagraphTeaserNewsroom | ParagraphTeasers | ParagraphTestimonialGallery | ParagraphTestimonialImage | ParagraphTextBox | ParagraphTextTeaser | ParagraphTitle | ParagraphTwoColumnText | ParagraphTwoColumnTextImage | ParagraphVideo | ParagraphWebform;

export type BreadcrumbLink = {
  __typename?: 'BreadcrumbLink';
  _original_typename: Scalars['String'];
  options: Maybe<LinkOptions>;
  title: Scalars['String'];
  uri: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type ContentBlog = NodeInterface & {
  __typename?: 'ContentBlog';
  _original_typename: Scalars['String'];
  blogCategory: Array<Maybe<TermBlogCategory>>;
  blogOrganisation: Array<Maybe<TermBlogOrganisation>>;
  blogReference: Array<Maybe<ContentBlog>>;
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  content: Maybe<Array<Maybe<AllParagraphs>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  headerImage: Maybe<MediaImage>;
  heroButtonLink: Maybe<Link>;
  heroButtonType: Maybe<Scalars['String']>;
  hideEmployerBranding: Maybe<Scalars['Boolean']>;
  hideFundingBanner: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  langcode: Scalars['String'];
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  newsType: Array<Maybe<TermNewsType>>;
  path: Scalars['String'];
  sticky: Maybe<Scalars['Boolean']>;
  subTitle: Maybe<Scalars['String']>;
  teaserImage: Maybe<MediaImage>;
  title: Scalars['String'];
  translations: Array<ContentBlog>;
};

export type ContentBreakingNews = NodeInterface & {
  __typename?: 'ContentBreakingNews';
  _original_typename: Scalars['String'];
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  content: Maybe<Array<Maybe<AllParagraphs>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  hideFundingBanner: Maybe<Scalars['Boolean']>;
  hideSidebar: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  langcode: Scalars['String'];
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  overlayImage: Maybe<MediaImage>;
  overlayInterval: Maybe<Scalars['Int']>;
  overlayMoreLink: Maybe<Link>;
  overlayMoreText: Maybe<Scalars['String']>;
  overlaySubTitle: Maybe<Scalars['String']>;
  overlayText: Maybe<Scalars['String']>;
  overlayTitle: Maybe<Scalars['String']>;
  overlayType: Maybe<Scalars['String']>;
  path: Scalars['String'];
  showOverlay: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  translations: Array<ContentBreakingNews>;
};

export type ContentCompany = NodeInterface & {
  __typename?: 'ContentCompany';
  _original_typename: Scalars['String'];
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  content: Maybe<Array<Maybe<AllParagraphs>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  details: Maybe<Scalars['String']>;
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  email: Maybe<Scalars['String']>;
  hasContent: Maybe<Scalars['Boolean']>;
  heroButtonLink: Maybe<Link>;
  heroButtonType: Maybe<Scalars['String']>;
  hideFundingBanner: Maybe<Scalars['Boolean']>;
  hideSidebar: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  industry: Array<Maybe<TermIndustry>>;
  langcode: Scalars['String'];
  logo: Maybe<MediaImage>;
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  paraworkJobCompany: Maybe<Scalars['Boolean']>;
  paraworkJobType: Array<Maybe<TermParaworkJobType>>;
  path: Scalars['String'];
  place: Scalars['String'];
  streetAndNumber: Scalars['String'];
  telephone: Maybe<Scalars['String']>;
  title: Scalars['String'];
  translations: Array<ContentCompany>;
  websiteLink: Maybe<Link>;
  zip: Maybe<Scalars['String']>;
};

export type ContentCompetences = NodeInterface & {
  __typename?: 'ContentCompetences';
  _original_typename: Scalars['String'];
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  content: Maybe<Array<Maybe<AllParagraphs>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  path: Scalars['String'];
  title: Scalars['String'];
  translations: Array<ContentCompetences>;
};

export type ContentDoctor = {
  __typename?: 'ContentDoctor';
  _original_typename: Scalars['String'];
  additionalFields: Array<Maybe<TermFieldOfExpertise>>;
  additionalInformation: Maybe<Scalars['String']>;
  bannerImage: Maybe<MediaImage>;
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  doctorFunction: Maybe<TermDoctorFunction>;
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  email: Maybe<Scalars['String']>;
  fax: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  job: Maybe<Scalars['String']>;
  langcode: Scalars['String'];
  lastName: Scalars['String'];
  mainField: TermFieldOfExpertise;
  memberships: Array<Maybe<Scalars['String']>>;
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  path: Scalars['String'];
  professionalCareer: Maybe<Array<Maybe<ParagraphRow>>>;
  publications: Array<Maybe<Scalars['String']>>;
  specialistDepartment: Maybe<Scalars['String']>;
  specialistLongTitle: Maybe<Scalars['String']>;
  specialistTeaserTitle: Maybe<Scalars['String']>;
  teaserImage: Maybe<MediaImage>;
  telephone: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trainingEducation: Maybe<Array<Maybe<ParagraphRow>>>;
  translations: Array<ContentDoctor>;
  treatmentAreas: Maybe<Scalars['String']>;
};

export type ContentDonationPayment = ContentFirstLevelPage | ContentSecondLevelPage;

export type ContentEvent = NodeInterface & {
  __typename?: 'ContentEvent';
  _original_typename: Scalars['String'];
  blogOrganisation: Array<Maybe<TermBlogOrganisation>>;
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  category: Array<Maybe<TermEventCategory>>;
  content: Maybe<Array<Maybe<AllParagraphs>>>;
  created: Maybe<Scalars['Int']>;
  date: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  headerImage: Maybe<MediaImage>;
  hideFundingBanner: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  info: Maybe<ParagraphTable>;
  langcode: Scalars['String'];
  leadHtml: Maybe<Scalars['String']>;
  map: Maybe<ParagraphMap>;
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  path: Scalars['String'];
  teaserImage: Maybe<MediaImage>;
  title: Scalars['String'];
  translations: Array<ContentEvent>;
};

export type ContentFirstLevelPage = NodeInterface & {
  __typename?: 'ContentFirstLevelPage';
  _original_typename: Scalars['String'];
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  content: Maybe<Array<Maybe<AllParagraphs>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  donationPaymentPage: Maybe<ContentDonationPayment>;
  drupalId: Scalars['String'];
  headerSlider: Maybe<AllParagraphs>;
  heroButtonLink: Maybe<Link>;
  heroButtonType: Maybe<Scalars['String']>;
  hideCallToActionInHeader: Maybe<Scalars['Boolean']>;
  hideEmployerBranding: Maybe<Scalars['Boolean']>;
  hideFundingBanner: Maybe<Scalars['Boolean']>;
  hideHeader: Maybe<Scalars['Boolean']>;
  hideSidebar: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  langcode: Scalars['String'];
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  path: Scalars['String'];
  sendToAllAffiliates: Maybe<Scalars['Boolean']>;
  showDonationWidget: Maybe<Scalars['Boolean']>;
  showGiftMembershipBox: Maybe<Scalars['Boolean']>;
  showMembershipBox: Maybe<Scalars['Boolean']>;
  teaserGrid: Maybe<AllParagraphs>;
  title: Scalars['String'];
  translations: Array<ContentFirstLevelPage>;
};

export type ContentFormRedirect = ContentBlog | ContentFirstLevelPage | ContentSecondLevelPage | ContentThankYouPage | ContentThirdLevelPage;

export type ContentHistory = {
  __typename?: 'ContentHistory';
  _original_typename: Scalars['String'];
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  content: Maybe<Array<Maybe<AllParagraphs>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  title: Scalars['String'];
  translations: Array<ContentHistory>;
};

export type ContentKitchenSink = NodeInterface & {
  __typename?: 'ContentKitchenSink';
  _original_typename: Scalars['String'];
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  paragraphs: Maybe<Array<Maybe<AllParagraphs>>>;
  path: Scalars['String'];
  title: Scalars['String'];
  translations: Array<ContentKitchenSink>;
};

export type ContentNews = NodeInterface & {
  __typename?: 'ContentNews';
  _original_typename: Scalars['String'];
  blogOrganisation: Array<Maybe<TermBlogOrganisation>>;
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  content: Maybe<Array<Maybe<AllParagraphs>>>;
  created: Maybe<Scalars['Int']>;
  date: Maybe<Scalars['String']>;
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  hideFundingBanner: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  langcode: Scalars['String'];
  leadHtml: Maybe<Scalars['String']>;
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  newsType: Array<Maybe<TermNewsType>>;
  path: Scalars['String'];
  sticky: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  translations: Array<ContentNews>;
};

export type ContentSecondLevelPage = NodeInterface & {
  __typename?: 'ContentSecondLevelPage';
  _original_typename: Scalars['String'];
  addToMedicalOffersFilter: Maybe<Scalars['Boolean']>;
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  content: Maybe<Array<Maybe<AllParagraphs>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  headerImage: Maybe<MediaImage>;
  heroButtonLink: Maybe<Link>;
  heroButtonType: Maybe<Scalars['String']>;
  hideCallToActionInHeader: Maybe<Scalars['Boolean']>;
  hideEmployerBranding: Maybe<Scalars['Boolean']>;
  hideFundingBanner: Maybe<Scalars['Boolean']>;
  hideHeader: Maybe<Scalars['Boolean']>;
  hideSidebar: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  langcode: Scalars['String'];
  medicalDomain: Array<Maybe<TermMedicalDomain>>;
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  path: Scalars['String'];
  title: Scalars['String'];
  translations: Array<ContentSecondLevelPage>;
};

export type ContentThankYouPage = NodeInterface & {
  __typename?: 'ContentThankYouPage';
  _original_typename: Scalars['String'];
  backLink: Maybe<Link>;
  body: Maybe<Scalars['String']>;
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  content: Maybe<Array<Maybe<AllParagraphs>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  hideCallToActionInHeader: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  image: Maybe<MediaImage>;
  langcode: Scalars['String'];
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  path: Scalars['String'];
  subTitle: Maybe<Scalars['String']>;
  title: Scalars['String'];
  translations: Array<ContentThankYouPage>;
};

export type ContentThirdLevelPage = NodeInterface & {
  __typename?: 'ContentThirdLevelPage';
  _original_typename: Scalars['String'];
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  content: Maybe<Array<Maybe<AllParagraphs>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  heroButtonLink: Maybe<Link>;
  heroButtonType: Maybe<Scalars['String']>;
  hideCallToActionInHeader: Maybe<Scalars['Boolean']>;
  hideEmployerBranding: Maybe<Scalars['Boolean']>;
  hideFundingBanner: Maybe<Scalars['Boolean']>;
  hideSidebar: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  langcode: Scalars['String'];
  metaTags: Maybe<Array<Maybe<MetaTag>>>;
  path: Scalars['String'];
  title: Scalars['String'];
  translations: Array<ContentThirdLevelPage>;
};

export type ContentZsmForm = NodeInterface & {
  __typename?: 'ContentZsmForm';
  _original_typename: Scalars['String'];
  breadcrumbs: Maybe<Array<Maybe<BreadcrumbLink>>>;
  created: Maybe<Scalars['Int']>;
  defaultTranslation: Scalars['Boolean'];
  domainAccess: Array<Maybe<Domain>>;
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  leadHtml: Maybe<Scalars['String']>;
  modules: Maybe<Array<Maybe<ParagraphEventModule>>>;
  path: Scalars['String'];
  title: Scalars['String'];
  translations: Array<ContentZsmForm>;
};

export type Domain = {
  __typename?: 'Domain';
  _original_typename: Scalars['String'];
  domainId: Scalars['String'];
  hostname: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Feed = {
  __typename?: 'Feed';
  _original_typename: Scalars['String'];
  changes: Array<Scalars['String']>;
  listFieldName: Scalars['String'];
  pathFieldName: Maybe<Scalars['String']>;
  singleFieldName: Scalars['String'];
  templateFieldName: Maybe<Scalars['String']>;
  translatable: Scalars['Boolean'];
  typeName: Scalars['String'];
};


export type FeedChangesArgs = {
  currentBuild: InputMaybe<Scalars['Int']>;
  lastBuild: InputMaybe<Scalars['Int']>;
};

export type ImageStyle = {
  __typename?: 'ImageStyle';
  _original_typename: Scalars['String'];
  height: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  url: Scalars['String'];
  width: Maybe<Scalars['Int']>;
};

export type Item = {
  __typename?: 'Item';
  _original_typename: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
};

export type Link = {
  __typename?: 'Link';
  _original_typename: Scalars['String'];
  options: Maybe<LinkOptions>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type LinkAttributes = {
  __typename?: 'LinkAttributes';
  _original_typename: Scalars['String'];
  target: Maybe<Scalars['String']>;
};

export type LinkOptions = {
  __typename?: 'LinkOptions';
  _original_typename: Scalars['String'];
  attributes: Maybe<LinkAttributes>;
};

export type LinkWithTranslations = {
  __typename?: 'LinkWithTranslations';
  _original_typename: Scalars['String'];
  translations: Maybe<Array<Maybe<TranslatedLink>>>;
};

export type ListingCompanyParaworkSearchFilters = {
  __typename?: 'ListingCompanyParaworkSearchFilters';
  _original_typename: Scalars['String'];
  industry: Array<Item>;
  paraworkJobType: Array<Item>;
};

export type ListingCompanyParaworkSearchFiltersInput = {
  glossary: InputMaybe<Scalars['String']>;
  industry: InputMaybe<Array<Scalars['String']>>;
  language: InputMaybe<Scalars['String']>;
  paraworkJobType: InputMaybe<Array<Scalars['String']>>;
  text: InputMaybe<Scalars['String']>;
};

export type ListingCompanySearchFilters = {
  __typename?: 'ListingCompanySearchFilters';
  _original_typename: Scalars['String'];
  industry: Array<Item>;
};

export type ListingCompanySearchFiltersInput = {
  glossary: InputMaybe<Scalars['String']>;
  industry: InputMaybe<Array<Scalars['String']>>;
  language: InputMaybe<Scalars['String']>;
  text: InputMaybe<Scalars['String']>;
};

export type ListingDoctorSearchFilters = {
  __typename?: 'ListingDoctorSearchFilters';
  _original_typename: Scalars['String'];
  doctorFunction: Array<Item>;
  field: Array<Item>;
};

export type ListingDoctorSearchFiltersInput = {
  doctorFunction: InputMaybe<Array<Scalars['String']>>;
  field: InputMaybe<Array<Scalars['String']>>;
  glossary: InputMaybe<Scalars['String']>;
  language: InputMaybe<Scalars['String']>;
};

export type ListingMedicalOfferSearchFilters = {
  __typename?: 'ListingMedicalOfferSearchFilters';
  _original_typename: Scalars['String'];
  medicalDomain: Array<Item>;
};

export type ListingMedicalOfferSearchFiltersInput = {
  glossary: InputMaybe<Scalars['String']>;
  language: InputMaybe<Scalars['String']>;
  medicalDomain: InputMaybe<Array<Scalars['String']>>;
};

export type ListingNewsroomExtrasInput = {
  highlightedCount: InputMaybe<Scalars['Int']>;
};

export type ListingNewsroomFilters = {
  __typename?: 'ListingNewsroomFilters';
  _original_typename: Scalars['String'];
  news_type: Array<Item>;
  organisation: Array<Item>;
};

export type ListingNewsroomFiltersInput = {
  language: InputMaybe<Scalars['String']>;
  news_type: Array<Scalars['String']>;
  organisation: InputMaybe<Scalars['String']>;
};

export type ListingResultContentCompany = {
  __typename?: 'ListingResultContentCompany';
  _original_typename: Scalars['String'];
  items: Array<Maybe<ContentCompany>>;
  total: Scalars['Int'];
};

export type ListingResultContentParaworkCompany = {
  __typename?: 'ListingResultContentParaworkCompany';
  _original_typename: Scalars['String'];
  items: Array<Maybe<ContentCompany>>;
  total: Scalars['Int'];
};

export type ListingResultDoctor = {
  __typename?: 'ListingResultDoctor';
  _original_typename: Scalars['String'];
  /**  Medical Offer is a ContentSecondLevelPage with a flag.  */
  items: Array<Maybe<ContentDoctor>>;
  total: Scalars['Int'];
};

export type ListingResultMedicalOffer = {
  __typename?: 'ListingResultMedicalOffer';
  _original_typename: Scalars['String'];
  /**  Medical Offer is a ContentSecondLevelPage with a flag.  */
  items: Array<Maybe<ContentSecondLevelPage>>;
  total: Scalars['Int'];
};

export type ListingResultNewsroom = {
  __typename?: 'ListingResultNewsroom';
  _original_typename: Scalars['String'];
  items: Maybe<Array<Maybe<NewsroomItems>>>;
  total: Scalars['Int'];
};

export type MediaAudio = {
  __typename?: 'MediaAudio';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  mimeType: Maybe<Scalars['String']>;
  name: Scalars['String'];
  size: Maybe<Scalars['Int']>;
  translations: Array<MediaAudio>;
  url: Scalars['String'];
};

export type MediaDownloadable = MediaAudio | MediaFile;

export type MediaFile = {
  __typename?: 'MediaFile';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  mimeType: Maybe<Scalars['String']>;
  name: Scalars['String'];
  size: Maybe<Scalars['Int']>;
  translations: Array<MediaFile>;
  url: Scalars['String'];
};

export type MediaIcon = {
  __typename?: 'MediaIcon';
  _original_typename: Scalars['String'];
  alt: Maybe<Scalars['String']>;
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  height: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  langcode: Scalars['String'];
  mimeType: Maybe<Scalars['String']>;
  styles: Array<Maybe<ImageStyle>>;
  title: Maybe<Scalars['String']>;
  translations: Array<MediaIcon>;
  url: Scalars['String'];
  width: Maybe<Scalars['Int']>;
};

export type MediaImage = {
  __typename?: 'MediaImage';
  _original_typename: Scalars['String'];
  alt: Maybe<Scalars['String']>;
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  height: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  langcode: Scalars['String'];
  mimeType: Maybe<Scalars['String']>;
  name: Scalars['String'];
  styles: Array<Maybe<ImageStyle>>;
  title: Maybe<Scalars['String']>;
  translations: Array<MediaImage>;
  url: Scalars['String'];
  width: Maybe<Scalars['Int']>;
};

export type MediaOptions = MediaImage | MediaRemoteVideo | MediaVideo;

export type MediaRemoteVideo = {
  __typename?: 'MediaRemoteVideo';
  _original_typename: Scalars['String'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type MediaVideo = {
  __typename?: 'MediaVideo';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  mimeType: Maybe<Scalars['String']>;
  name: Scalars['String'];
  translations: Array<MediaVideo>;
  url: Scalars['String'];
};

export type MenuFastNavigation = {
  __typename?: 'MenuFastNavigation';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFastNavigation>;
};

export type MenuFooter = {
  __typename?: 'MenuFooter';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooter>;
};

export type MenuFooterQuickLinksActiveCom = {
  __typename?: 'MenuFooterQuickLinksActiveCom';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooterQuickLinksActiveCom>;
};

export type MenuFooterQuickLinksGonnerVereinigung = {
  __typename?: 'MenuFooterQuickLinksGonnerVereinigung';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooterQuickLinksGonnerVereinigung>;
};

export type MenuFooterQuickLinksOrthotec = {
  __typename?: 'MenuFooterQuickLinksOrthotec';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooterQuickLinksOrthotec>;
};

export type MenuFooterQuickLinksOrthotecSports = {
  __typename?: 'MenuFooterQuickLinksOrthotecSports';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooterQuickLinksOrthotecSports>;
};

export type MenuFooterQuickLinksParahelp = {
  __typename?: 'MenuFooterQuickLinksParahelp';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooterQuickLinksParahelp>;
};

export type MenuFooterQuickLinksSpf = {
  __typename?: 'MenuFooterQuickLinksSPF';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooterQuickLinksSpf>;
};

export type MenuFooterQuickLinksSps = {
  __typename?: 'MenuFooterQuickLinksSPS';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooterQuickLinksSps>;
};

export type MenuFooterQuickLinksSpz = {
  __typename?: 'MenuFooterQuickLinksSPZ';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooterQuickLinksSpz>;
};

export type MenuFooterQuickLinksSirmed = {
  __typename?: 'MenuFooterQuickLinksSirmed';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooterQuickLinksSirmed>;
};

export type MenuFooterQuickLinksSportMedizin = {
  __typename?: 'MenuFooterQuickLinksSportMedizin';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooterQuickLinksSportMedizin>;
};

export type MenuHome = {
  __typename?: 'MenuHome';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuHome>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  _original_typename: Scalars['String'];
  accessKeyAttribute: Maybe<Scalars['String']>;
  ariaControlsAttribute: Maybe<Scalars['String']>;
  ariaExpandedAttribute: Maybe<Scalars['String']>;
  ariaHasPopupAttribute: Maybe<Scalars['String']>;
  classAttribute: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['String'];
  idAttribute: Maybe<Scalars['String']>;
  isEnabled: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  parent: Scalars['String'];
  targetAttribute: Maybe<Scalars['String']>;
  titleAttribute: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type MenuMain = {
  __typename?: 'MenuMain';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuMain>;
};

export type MenuMetaNavigation = {
  __typename?: 'MenuMetaNavigation';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuMetaNavigation>;
};

export type MenuMetaNavigationOrthotecSports = {
  __typename?: 'MenuMetaNavigationOrthotecSports';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuMetaNavigationOrthotecSports>;
};

export type MenuSidebarActiveCommunication = {
  __typename?: 'MenuSidebarActiveCommunication';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSidebarActiveCommunication>;
};

export type MenuSidebarGonnerVereinigung = {
  __typename?: 'MenuSidebarGonnerVereinigung';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSidebarGonnerVereinigung>;
};

export type MenuSidebarOrthotec = {
  __typename?: 'MenuSidebarOrthotec';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSidebarOrthotec>;
};

export type MenuSidebarOrthotecSports = {
  __typename?: 'MenuSidebarOrthotecSports';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSidebarOrthotecSports>;
};

export type MenuSidebarParahelp = {
  __typename?: 'MenuSidebarParahelp';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSidebarParahelp>;
};

export type MenuSidebarSpf = {
  __typename?: 'MenuSidebarSPF';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSidebarSpf>;
};

export type MenuSidebarSps = {
  __typename?: 'MenuSidebarSPS';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSidebarSps>;
};

export type MenuSidebarSpz = {
  __typename?: 'MenuSidebarSPZ';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSidebarSpz>;
};

export type MenuSidebarSirmed = {
  __typename?: 'MenuSidebarSirmed';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSidebarSirmed>;
};

export type MenuSidebarSportMedizin = {
  __typename?: 'MenuSidebarSportMedizin';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSidebarSportMedizin>;
};

export type MenuSocial = {
  __typename?: 'MenuSocial';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSocial>;
};

export type MenuSocialActiveCom = {
  __typename?: 'MenuSocialActiveCom';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSocialActiveCom>;
};

export type MenuSocialOrthotec = {
  __typename?: 'MenuSocialOrthotec';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSocialOrthotec>;
};

export type MenuSocialParahelp = {
  __typename?: 'MenuSocialParahelp';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSocialParahelp>;
};

export type MenuSocialSpf = {
  __typename?: 'MenuSocialSPF';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSocialSpf>;
};

export type MenuSocialSps = {
  __typename?: 'MenuSocialSPS';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSocialSps>;
};

export type MenuSocialSpz = {
  __typename?: 'MenuSocialSPZ';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSocialSpz>;
};

export type MenuSocialSirmed = {
  __typename?: 'MenuSocialSirmed';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSocialSirmed>;
};

export type MetaTag = {
  __typename?: 'MetaTag';
  _original_typename: Scalars['String'];
  attributes: MetaTagAttributes;
  tag: Scalars['String'];
};

export type MetaTagAttributes = {
  __typename?: 'MetaTagAttributes';
  _original_typename: Scalars['String'];
  content: Maybe<Scalars['String']>;
  href: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  property: Maybe<Scalars['String']>;
  rel: Maybe<Scalars['String']>;
};

export type NewsroomItems = ContentBlog | ContentNews;

export type NodeInterface = {
  domainAccess: Array<Maybe<Domain>>;
  path: Scalars['String'];
};

export type PaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ParagraphAccordion = PublishableInterface & {
  __typename?: 'ParagraphAccordion';
  _original_typename: Scalars['String'];
  collapsible: Maybe<Scalars['Boolean']>;
  items: Maybe<Array<Maybe<ParagraphAccordionItem>>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphAccordionDownloadItem = ParagraphAccordionItemInterface & PublishableInterface & {
  __typename?: 'ParagraphAccordionDownloadItem';
  _original_typename: Scalars['String'];
  content: Maybe<Array<Maybe<ParagraphAccordionItemContent>>>;
  file: Maybe<MediaDownloadable>;
  hasLogoImage: Maybe<Scalars['Boolean']>;
  iconClass: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  itemTitle: Maybe<Scalars['String']>;
  subTitle: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphAccordionItem = ParagraphAccordionDownloadItem | ParagraphAccordionLinkItem | ParagraphAccordionSimpleItem;

export type ParagraphAccordionItemContent = ParagraphCopyText | ParagraphDownloadList | ParagraphImage | ParagraphLinkList | ParagraphLogoBar | ParagraphOrthotecGallery | ParagraphOrthotecValueTable | ParagraphTable | ParagraphTeamList | ParagraphTitle | ParagraphVideo;

export type ParagraphAccordionItemInterface = {
  content: Maybe<Array<Maybe<ParagraphAccordionItemContent>>>;
  hasLogoImage: Maybe<Scalars['Boolean']>;
  image: Maybe<MediaImage>;
  itemTitle: Maybe<Scalars['String']>;
  subTitle: Maybe<Scalars['String']>;
};

export type ParagraphAccordionLinkItem = ParagraphAccordionItemInterface & PublishableInterface & {
  __typename?: 'ParagraphAccordionLinkItem';
  _original_typename: Scalars['String'];
  content: Maybe<Array<Maybe<ParagraphAccordionItemContent>>>;
  hasLogoImage: Maybe<Scalars['Boolean']>;
  iconClass: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  itemTitle: Maybe<Scalars['String']>;
  link: Maybe<Link>;
  subTitle: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphAccordionSimpleItem = ParagraphAccordionItemInterface & PublishableInterface & {
  __typename?: 'ParagraphAccordionSimpleItem';
  _original_typename: Scalars['String'];
  content: Maybe<Array<Maybe<ParagraphAccordionItemContent>>>;
  hasLogoImage: Maybe<Scalars['Boolean']>;
  iconClass: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  itemTitle: Maybe<Scalars['String']>;
  subTitle: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphAnchorTag = {
  __typename?: 'ParagraphAnchorTag';
  _original_typename: Scalars['String'];
  anchor: Scalars['String'];
};

export type ParagraphBlockTeaser = PublishableInterface & {
  __typename?: 'ParagraphBlockTeaser';
  _original_typename: Scalars['String'];
  descriptionHtml: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  link: Maybe<Link>;
  priceAdditionalInfo: Maybe<Scalars['String']>;
  subTitle: Maybe<Scalars['String']>;
  teaserTitle: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphBlockTeaserList = PublishableInterface & {
  __typename?: 'ParagraphBlockTeaserList';
  _original_typename: Scalars['String'];
  hasBackground: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['String']>;
  teasers: Maybe<Array<Maybe<ParagraphBlockTeaser>>>;
  threeInARow: Maybe<Scalars['Boolean']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphCallToAction = PublishableInterface & {
  __typename?: 'ParagraphCallToAction';
  _original_typename: Scalars['String'];
  buttonStyle: Scalars['String'];
  link: Maybe<Link>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphCompetencesAccordion = PublishableInterface & {
  __typename?: 'ParagraphCompetencesAccordion';
  _original_typename: Scalars['String'];
  content: Maybe<Array<Maybe<ParagraphCompetencesAccordionContent>>>;
  image: Maybe<MediaImage>;
  leadHtml: Scalars['String'];
  title: Scalars['String'];
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphCompetencesAccordionContent = ParagraphAccordion | ParagraphLinkList;

export type ParagraphContent = {
  __typename?: 'ParagraphContent';
  _original_typename: Scalars['String'];
  items: Maybe<Array<Maybe<AllParagraphs>>>;
};

export type ParagraphContentBox = PublishableInterface & {
  __typename?: 'ParagraphContentBox';
  _original_typename: Scalars['String'];
  backgroundImage: Maybe<MediaImage>;
  descriptionHtml: Maybe<Scalars['String']>;
  icon: Maybe<MediaIcon>;
  links: Maybe<Array<Maybe<Link>>>;
  title: Scalars['String'];
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphConversionWidget = PublishableInterface & {
  __typename?: 'ParagraphConversionWidget';
  _original_typename: Scalars['String'];
  displayMode: Scalars['String'];
  donationPaymentLink: Maybe<Link>;
  donationPaymentPage: Maybe<ContentDonationPayment>;
  unpublished: Maybe<Scalars['Boolean']>;
  widgetType: Scalars['String'];
};

export type ParagraphCopyText = PublishableInterface & {
  __typename?: 'ParagraphCopyText';
  _original_typename: Scalars['String'];
  hasBackground: Maybe<Scalars['Boolean']>;
  textHtml: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphCustomHtml = PublishableInterface & {
  __typename?: 'ParagraphCustomHtml';
  _original_typename: Scalars['String'];
  html: Scalars['String'];
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphCustomMarkup = PublishableInterface & {
  __typename?: 'ParagraphCustomMarkup';
  _original_typename: Scalars['String'];
  hasBackground: Maybe<Scalars['Boolean']>;
  textHtml: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphDonationWidget = PublishableInterface & {
  __typename?: 'ParagraphDonationWidget';
  _original_typename: Scalars['String'];
  amounts: Array<Maybe<Scalars['Int']>>;
  campaignId: Maybe<Scalars['String']>;
  thankYouPageLink: Maybe<Link>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphDownloadList = PublishableInterface & {
  __typename?: 'ParagraphDownloadList';
  _original_typename: Scalars['String'];
  files: Maybe<Array<Maybe<MediaDownloadable>>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphEmpty = {
  __typename?: 'ParagraphEmpty';
  _original_typename: Scalars['String'];
  void: Maybe<Scalars['Void']>;
};

export type ParagraphEventModule = PublishableInterface & {
  __typename?: 'ParagraphEventModule';
  _original_typename: Scalars['String'];
  descriptionHtml: Maybe<Scalars['String']>;
  eventTitle: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  prices: Array<Maybe<ParagraphPrice>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphFloatingQuote = PublishableInterface & {
  __typename?: 'ParagraphFloatingQuote';
  _original_typename: Scalars['String'];
  author: Maybe<Scalars['String']>;
  overlap: Maybe<Scalars['String']>;
  quoteHtml: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphFromLibrary = {
  __typename?: 'ParagraphFromLibrary';
  _original_typename: Scalars['String'];
  reusableParagraph: Maybe<ParagraphLibraryItem>;
};

export type ParagraphFullWidthGalleryImage = PublishableInterface & {
  __typename?: 'ParagraphFullWidthGalleryImage';
  _original_typename: Scalars['String'];
  descriptionHtml: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphGallery = PublishableInterface & {
  __typename?: 'ParagraphGallery';
  _original_typename: Scalars['String'];
  images: Array<Maybe<ParagraphGalleryImage>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphGalleryImage = PublishableInterface & {
  __typename?: 'ParagraphGalleryImage';
  _original_typename: Scalars['String'];
  descriptionHtml: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphHomeSliderItem = PublishableInterface & {
  __typename?: 'ParagraphHomeSliderItem';
  _original_typename: Scalars['String'];
  descriptionHtml: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  itemTitle: Maybe<Scalars['String']>;
  link: Maybe<Link>;
  mobileImage: Maybe<MediaImage>;
  mobileVideo: Maybe<MediaVideo>;
  unpublished: Maybe<Scalars['Boolean']>;
  video: Maybe<MediaVideo>;
};

export type ParagraphIframe = PublishableInterface & {
  __typename?: 'ParagraphIframe';
  _original_typename: Scalars['String'];
  height: Maybe<Scalars['Int']>;
  unpublished: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
};

export type ParagraphImage = PublishableInterface & {
  __typename?: 'ParagraphImage';
  _original_typename: Scalars['String'];
  descriptionHtml: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  imageSize: Scalars['String'];
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphImageGrid = PublishableInterface & {
  __typename?: 'ParagraphImageGrid';
  _original_typename: Scalars['String'];
  leftImage: Maybe<MediaImage>;
  rightImage: Maybe<MediaImage>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphImageTeaser = PublishableInterface & {
  __typename?: 'ParagraphImageTeaser';
  _original_typename: Scalars['String'];
  image: Maybe<MediaImage>;
  link: Maybe<Link>;
  textHtml: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphInfoBox = PublishableInterface & {
  __typename?: 'ParagraphInfoBox';
  _original_typename: Scalars['String'];
  boxTitle: Maybe<Scalars['String']>;
  iconClass: Maybe<Scalars['String']>;
  textHtml: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphInfoBoxList = PublishableInterface & {
  __typename?: 'ParagraphInfoBoxList';
  _original_typename: Scalars['String'];
  items: Maybe<Array<Maybe<ParagraphInfoBox>>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphItemList = PublishableInterface & {
  __typename?: 'ParagraphItemList';
  _original_typename: Scalars['String'];
  hasBackground: Maybe<Scalars['Boolean']>;
  items: Maybe<Array<Maybe<ParagraphContentBox>>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphLibrary = ParagraphAccordion | ParagraphAnchorTag | ParagraphBlockTeaserList | ParagraphCallToAction | ParagraphCompetencesAccordion | ParagraphCopyText | ParagraphCustomHtml | ParagraphCustomMarkup | ParagraphDonationWidget | ParagraphDownloadList | ParagraphEventModule | ParagraphFloatingQuote | ParagraphGallery | ParagraphIframe | ParagraphImage | ParagraphImageGrid | ParagraphInfoBoxList | ParagraphItemList | ParagraphLinkList | ParagraphLogoBar | ParagraphMap | ParagraphOrthotecGallery | ParagraphOrthotecValueTable | ParagraphRaiseNowForm | ParagraphSlider | ParagraphStorifyMe | ParagraphStorytelling | ParagraphTable | ParagraphTeamList | ParagraphTeasers | ParagraphTestimonialGallery | ParagraphTestimonialImage | ParagraphTitle | ParagraphTwoColumnText | ParagraphTwoColumnTextImage | ParagraphVideo;

export type ParagraphLibraryItem = {
  __typename?: 'ParagraphLibraryItem';
  _original_typename: Scalars['String'];
  langcode: Maybe<Scalars['String']>;
  paragraph: Maybe<ParagraphLibrary>;
  title: Maybe<Scalars['String']>;
};

export type ParagraphLinkList = PublishableInterface & {
  __typename?: 'ParagraphLinkList';
  _original_typename: Scalars['String'];
  links: Maybe<Array<Maybe<Link>>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphListingCompanyParaworkSearch = {
  __typename?: 'ParagraphListingCompanyParaworkSearch';
  _original_typename: Scalars['String'];
  argument: Maybe<Scalars['String']>;
  companyFilters: ListingCompanyParaworkSearchFilters;
  label: Maybe<Scalars['String']>;
  /**  Indicates which root field to use to get the list  */
  rootListField: Maybe<Scalars['String']>;
};

export type ParagraphListingCompanySearch = {
  __typename?: 'ParagraphListingCompanySearch';
  _original_typename: Scalars['String'];
  argument: Maybe<Scalars['String']>;
  companyFilters: ListingCompanySearchFilters;
  label: Maybe<Scalars['String']>;
  /**  Indicates which root field to use to get the list  */
  rootListField: Maybe<Scalars['String']>;
};

export type ParagraphListingDoctorSearch = {
  __typename?: 'ParagraphListingDoctorSearch';
  _original_typename: Scalars['String'];
  argument: Maybe<Scalars['String']>;
  doctorFilters: ListingDoctorSearchFilters;
  label: Maybe<Scalars['String']>;
  /**  Indicates which root field to use to get the list  */
  rootListField: Maybe<Scalars['String']>;
};

export type ParagraphListingMedicalOfferSearch = {
  __typename?: 'ParagraphListingMedicalOfferSearch';
  _original_typename: Scalars['String'];
  argument: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  medicalOfferFilters: ListingMedicalOfferSearchFilters;
  /**  Indicates which root field to use to get the list  */
  rootListField: Maybe<Scalars['String']>;
};

export type ParagraphListingNewsroom = {
  __typename?: 'ParagraphListingNewsroom';
  _original_typename: Scalars['String'];
  argument: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  newsroomFilters: ListingNewsroomFilters;
  /**  Indicates which root field to use to get the list  */
  rootListField: Maybe<Scalars['String']>;
};

export type ParagraphLogo = PublishableInterface & {
  __typename?: 'ParagraphLogo';
  _original_typename: Scalars['String'];
  link: Maybe<Link>;
  logo: MediaImage;
  title: Scalars['String'];
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphLogoBar = PublishableInterface & {
  __typename?: 'ParagraphLogoBar';
  _original_typename: Scalars['String'];
  logos: Array<Maybe<ParagraphLogo>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphMap = PublishableInterface & {
  __typename?: 'ParagraphMap';
  _original_typename: Scalars['String'];
  latitude: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['String']>;
  mapTitle: Maybe<Scalars['String']>;
  smallVariant: Maybe<Scalars['Boolean']>;
  textHtml: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphMayorisPlaceholder = PublishableInterface & {
  __typename?: 'ParagraphMayorisPlaceholder';
  _original_typename: Scalars['String'];
  text: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphOrthotecGallery = PublishableInterface & {
  __typename?: 'ParagraphOrthotecGallery';
  _original_typename: Scalars['String'];
  images: Array<Maybe<MediaImage>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphOrthotecValueTable = PublishableInterface & {
  __typename?: 'ParagraphOrthotecValueTable';
  _original_typename: Scalars['String'];
  additionalEquipment: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  capacity: Maybe<Scalars['String']>;
  color: Maybe<Scalars['String']>;
  contact: Scalars['String'];
  email: Maybe<Scalars['String']>;
  fax: Maybe<Scalars['String']>;
  firstCirculation: Scalars['String'];
  inside: Maybe<Scalars['String']>;
  km: Scalars['String'];
  mfkDate: Maybe<Scalars['String']>;
  mobile: Maybe<Scalars['String']>;
  modification: Scalars['String'];
  newPrice: Maybe<Scalars['String']>;
  options: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  price: Scalars['String'];
  ps: Maybe<Scalars['String']>;
  published: Scalars['String'];
  unpublished: Maybe<Scalars['Boolean']>;
  year: Scalars['String'];
};

export type ParagraphPrice = PublishableInterface & {
  __typename?: 'ParagraphPrice';
  _original_typename: Scalars['String'];
  amount: Maybe<Scalars['Int']>;
  priceTitle: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphRaiseNowForm = PublishableInterface & {
  __typename?: 'ParagraphRaiseNowForm';
  _original_typename: Scalars['String'];
  backPage: Maybe<ContentFormRedirect>;
  formType: Scalars['String'];
  id: Maybe<Scalars['String']>;
  requestType: Maybe<Scalars['String']>;
  thankYouPage: Maybe<ContentFormRedirect>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphRow = PublishableInterface & {
  __typename?: 'ParagraphRow';
  _original_typename: Scalars['String'];
  rowContent: Scalars['String'];
  rowTitle: Scalars['String'];
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphSlider = PublishableInterface & {
  __typename?: 'ParagraphSlider';
  _original_typename: Scalars['String'];
  id: Maybe<Scalars['String']>;
  items: Maybe<Array<Maybe<ParagraphSliderItem>>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphSliderImage = PublishableInterface & {
  __typename?: 'ParagraphSliderImage';
  _original_typename: Scalars['String'];
  descriptionHtml: Maybe<Scalars['String']>;
  headingLevel: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  imageTitle: Maybe<Scalars['String']>;
  link: Maybe<Link>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphSliderItem = ParagraphHomeSliderItem | ParagraphSliderImage;

export type ParagraphStorifyMe = PublishableInterface & {
  __typename?: 'ParagraphStorifyMe';
  _original_typename: Scalars['String'];
  unpublished: Maybe<Scalars['Boolean']>;
  widgetId: Scalars['String'];
};

export type ParagraphStorytelling = PublishableInterface & {
  __typename?: 'ParagraphStorytelling';
  _original_typename: Scalars['String'];
  headingLevel: Maybe<Scalars['String']>;
  link: Maybe<Link>;
  media: Maybe<MediaOptions>;
  mediaAlignment: Scalars['String'];
  storyTitle: Scalars['String'];
  textHtml: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTable = PublishableInterface & {
  __typename?: 'ParagraphTable';
  _original_typename: Scalars['String'];
  rows: Array<Maybe<ParagraphTableRow>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTableRow = PublishableInterface & {
  __typename?: 'ParagraphTableRow';
  _original_typename: Scalars['String'];
  key: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
  value: Maybe<Scalars['String']>;
};

export type ParagraphTeamList = PublishableInterface & {
  __typename?: 'ParagraphTeamList';
  _original_typename: Scalars['String'];
  hasBackground: Maybe<Scalars['Boolean']>;
  headingLevel: Maybe<Scalars['String']>;
  teamList: Array<Maybe<ParagraphTeamListItem>>;
  teamTitle: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTeamListItem = ParagraphTeamListItemExtended | ParagraphTeamListItemSimple;

export type ParagraphTeamListItemContent = ParagraphCopyText | ParagraphImage | ParagraphTable | ParagraphTitle | ParagraphVideo;

export type ParagraphTeamListItemExtended = ParagraphTeamListItemInterface & PublishableInterface & {
  __typename?: 'ParagraphTeamListItemExtended';
  _original_typename: Scalars['String'];
  email: Maybe<Scalars['String']>;
  fax: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  job: Maybe<Scalars['String']>;
  name: Scalars['String'];
  teamListItemContent: Maybe<Array<Maybe<ParagraphTeamListItemContent>>>;
  telephone: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTeamListItemInterface = {
  email: Maybe<Scalars['String']>;
  fax: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  job: Maybe<Scalars['String']>;
  name: Scalars['String'];
  telephone: Maybe<Scalars['String']>;
};

export type ParagraphTeamListItemSimple = ParagraphTeamListItemInterface & PublishableInterface & {
  __typename?: 'ParagraphTeamListItemSimple';
  _original_typename: Scalars['String'];
  email: Maybe<Scalars['String']>;
  fax: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  job: Maybe<Scalars['String']>;
  name: Scalars['String'];
  telephone: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTeaserGrid = {
  __typename?: 'ParagraphTeaserGrid';
  _original_typename: Scalars['String'];
  items: Maybe<Array<Maybe<AllParagraphs>>>;
};

export type ParagraphTeaserNewsroom = {
  __typename?: 'ParagraphTeaserNewsroom';
  _original_typename: Scalars['String'];
  argument: Maybe<Scalars['String']>;
  contentTypes: Array<Maybe<Scalars['String']>>;
  domain: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  linkText: Maybe<Scalars['String']>;
  maxItems: Scalars['String'];
  newsroomFilters: ListingNewsroomFilters;
  pTitle: Maybe<Scalars['String']>;
  /**  Indicates which root field to use to get the list  */
  rootListField: Maybe<Scalars['String']>;
};

export type ParagraphTeasers = PublishableInterface & {
  __typename?: 'ParagraphTeasers';
  _original_typename: Scalars['String'];
  firstRow: Maybe<ParagraphImageTeaser>;
  secondRow: Array<Maybe<ParagraphTextTeaser>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTestimonialGallery = PublishableInterface & {
  __typename?: 'ParagraphTestimonialGallery';
  _original_typename: Scalars['String'];
  items: Maybe<Array<Maybe<ParagraphFullWidthGalleryImage>>>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTestimonialImage = PublishableInterface & {
  __typename?: 'ParagraphTestimonialImage';
  _original_typename: Scalars['String'];
  image: Maybe<MediaImage>;
  textItem: Maybe<ParagraphTextItem>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTextBox = PublishableInterface & {
  __typename?: 'ParagraphTextBox';
  _original_typename: Scalars['String'];
  subTitleHtml: Maybe<Scalars['String']>;
  titleHtml: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTextItem = ParagraphTextBox | ParagraphTextTeaser;

export type ParagraphTextTeaser = PublishableInterface & {
  __typename?: 'ParagraphTextTeaser';
  _original_typename: Scalars['String'];
  category: Maybe<TermCategory>;
  iconClass: Maybe<Scalars['String']>;
  link: Maybe<Link>;
  textHtml: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTitle = PublishableInterface & {
  __typename?: 'ParagraphTitle';
  _original_typename: Scalars['String'];
  hasBackground: Maybe<Scalars['Boolean']>;
  headingClass: Maybe<Scalars['String']>;
  headingLevel: Maybe<Scalars['String']>;
  title: Scalars['String'];
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTwoColumnText = PublishableInterface & {
  __typename?: 'ParagraphTwoColumnText';
  _original_typename: Scalars['String'];
  firstTextHtml: Maybe<Scalars['String']>;
  lastTextHtml: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphTwoColumnTextImage = PublishableInterface & {
  __typename?: 'ParagraphTwoColumnTextImage';
  _original_typename: Scalars['String'];
  headingLevel: Maybe<Scalars['String']>;
  image: Maybe<MediaImage>;
  textHtml: Maybe<Scalars['String']>;
  textImageTitle: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
};

export type ParagraphVideo = PublishableInterface & {
  __typename?: 'ParagraphVideo';
  _original_typename: Scalars['String'];
  unpublished: Maybe<Scalars['Boolean']>;
  videoUrl: Maybe<Scalars['String']>;
};

export type ParagraphWebform = PublishableInterface & {
  __typename?: 'ParagraphWebform';
  _original_typename: Scalars['String'];
  status: Maybe<Scalars['String']>;
  unpublished: Maybe<Scalars['Boolean']>;
  webformUrl: Maybe<Scalars['String']>;
};

export type PublishableInterface = {
  unpublished: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  _original_typename: Scalars['String'];
  currentUser: User;
  drupalBuildId: Scalars['Int'];
  drupalFeedInfo: Array<Feed>;
  listingCompanyParaworkSearch: ListingResultContentCompany;
  listingCompanySearch: ListingResultContentCompany;
  listingDoctorSearch: ListingResultDoctor;
  listingMedicalOfferSearch: ListingResultMedicalOffer;
  listingNewsroom: ListingResultNewsroom;
  loadContentBlog: Maybe<ContentBlog>;
  loadContentBlogRevision: Maybe<ContentBlog>;
  loadContentBreakingNews: Maybe<ContentBreakingNews>;
  loadContentBreakingNewsRevision: Maybe<ContentBreakingNews>;
  loadContentCompany: Maybe<ContentCompany>;
  loadContentCompanyRevision: Maybe<ContentCompany>;
  loadContentCompetences: Maybe<ContentCompetences>;
  loadContentCompetencesRevision: Maybe<ContentCompetences>;
  loadContentDoctor: Maybe<ContentDoctor>;
  loadContentDoctorRevision: Maybe<ContentDoctor>;
  loadContentEvent: Maybe<ContentEvent>;
  loadContentEventRevision: Maybe<ContentEvent>;
  loadContentFirstLevelPage: Maybe<ContentFirstLevelPage>;
  loadContentFirstLevelPageRevision: Maybe<ContentFirstLevelPage>;
  loadContentHistory: Maybe<ContentHistory>;
  loadContentHistoryRevision: Maybe<ContentHistory>;
  loadContentKitchenSink: Maybe<ContentKitchenSink>;
  loadContentKitchenSinkRevision: Maybe<ContentKitchenSink>;
  loadContentNews: Maybe<ContentNews>;
  loadContentNewsRevision: Maybe<ContentNews>;
  loadContentSecondLevelPage: Maybe<ContentSecondLevelPage>;
  loadContentSecondLevelPageRevision: Maybe<ContentSecondLevelPage>;
  loadContentThankYouPage: Maybe<ContentThankYouPage>;
  loadContentThankYouPageRevision: Maybe<ContentThankYouPage>;
  loadContentThirdLevelPage: Maybe<ContentThirdLevelPage>;
  loadContentThirdLevelPageRevision: Maybe<ContentThirdLevelPage>;
  loadContentZsmForm: Maybe<ContentZsmForm>;
  loadContentZsmFormRevision: Maybe<ContentZsmForm>;
  loadMediaAudio: Maybe<MediaAudio>;
  loadMediaAudioRevision: Maybe<MediaAudio>;
  loadMediaFile: Maybe<MediaFile>;
  loadMediaFileRevision: Maybe<MediaFile>;
  loadMediaIcon: Maybe<MediaIcon>;
  loadMediaIconRevision: Maybe<MediaIcon>;
  loadMediaImage: Maybe<MediaImage>;
  loadMediaImageRevision: Maybe<MediaImage>;
  loadMediaRemoteVideo: Maybe<MediaRemoteVideo>;
  loadMediaRemoteVideoRevision: Maybe<MediaRemoteVideo>;
  loadMediaVideo: Maybe<MediaVideo>;
  loadMediaVideoRevision: Maybe<MediaVideo>;
  loadMenuFastNavigation: Maybe<MenuFastNavigation>;
  loadMenuFooter: Maybe<MenuFooter>;
  loadMenuFooterQuickLinksActiveCom: Maybe<MenuFooterQuickLinksActiveCom>;
  loadMenuFooterQuickLinksGonnerVereinigung: Maybe<MenuFooterQuickLinksGonnerVereinigung>;
  loadMenuFooterQuickLinksOrthotec: Maybe<MenuFooterQuickLinksOrthotec>;
  loadMenuFooterQuickLinksOrthotecSports: Maybe<MenuFooterQuickLinksOrthotecSports>;
  loadMenuFooterQuickLinksParahelp: Maybe<MenuFooterQuickLinksParahelp>;
  loadMenuFooterQuickLinksSPF: Maybe<MenuFooterQuickLinksSpf>;
  loadMenuFooterQuickLinksSPS: Maybe<MenuFooterQuickLinksSps>;
  loadMenuFooterQuickLinksSPZ: Maybe<MenuFooterQuickLinksSpz>;
  loadMenuFooterQuickLinksSirmed: Maybe<MenuFooterQuickLinksSirmed>;
  loadMenuFooterQuickLinksSportMedizin: Maybe<MenuFooterQuickLinksSportMedizin>;
  loadMenuHome: Maybe<MenuHome>;
  loadMenuMain: Maybe<MenuMain>;
  loadMenuMetaNavigation: Maybe<MenuMetaNavigation>;
  loadMenuMetaNavigationOrthotecSports: Maybe<MenuMetaNavigationOrthotecSports>;
  loadMenuSidebarActiveCommunication: Maybe<MenuSidebarActiveCommunication>;
  loadMenuSidebarGonnerVereinigung: Maybe<MenuSidebarGonnerVereinigung>;
  loadMenuSidebarOrthotec: Maybe<MenuSidebarOrthotec>;
  loadMenuSidebarOrthotecSports: Maybe<MenuSidebarOrthotecSports>;
  loadMenuSidebarParahelp: Maybe<MenuSidebarParahelp>;
  loadMenuSidebarSPF: Maybe<MenuSidebarSpf>;
  loadMenuSidebarSPS: Maybe<MenuSidebarSps>;
  loadMenuSidebarSPZ: Maybe<MenuSidebarSpz>;
  loadMenuSidebarSirmed: Maybe<MenuSidebarSirmed>;
  loadMenuSidebarSportMedizin: Maybe<MenuSidebarSportMedizin>;
  loadMenuSocial: Maybe<MenuSocial>;
  loadMenuSocialActiveCom: Maybe<MenuSocialActiveCom>;
  loadMenuSocialOrthotec: Maybe<MenuSocialOrthotec>;
  loadMenuSocialParahelp: Maybe<MenuSocialParahelp>;
  loadMenuSocialSPF: Maybe<MenuSocialSpf>;
  loadMenuSocialSPS: Maybe<MenuSocialSps>;
  loadMenuSocialSPZ: Maybe<MenuSocialSpz>;
  loadMenuSocialSirmed: Maybe<MenuSocialSirmed>;
  loadSettingsCallToAction: Maybe<SettingsCallToAction>;
  loadSettingsCallToActionRevision: Maybe<SettingsCallToAction>;
  loadSettingsNewsroom: Maybe<SettingsNewsroom>;
  loadSettingsNewsroomRevision: Maybe<SettingsNewsroom>;
  loadSettingsStorifyMe: Maybe<SettingsStorifyMe>;
  loadSettingsStorifyMeRevision: Maybe<SettingsStorifyMe>;
  loadSettingsZsmForm: Maybe<SettingsZsmForm>;
  loadSettingsZsmFormRevision: Maybe<SettingsZsmForm>;
  loadTermBlogCategory: Maybe<TermBlogCategory>;
  loadTermBlogCategoryRevision: Maybe<TermBlogCategory>;
  loadTermBlogOrganisation: Maybe<TermBlogOrganisation>;
  loadTermBlogOrganisationRevision: Maybe<TermBlogOrganisation>;
  loadTermCategory: Maybe<TermCategory>;
  loadTermCategoryRevision: Maybe<TermCategory>;
  loadTermDoctorFunction: Maybe<TermDoctorFunction>;
  loadTermDoctorFunctionRevision: Maybe<TermDoctorFunction>;
  loadTermEventCategory: Maybe<TermEventCategory>;
  loadTermEventCategoryRevision: Maybe<TermEventCategory>;
  loadTermFieldOfExpertise: Maybe<TermFieldOfExpertise>;
  loadTermFieldOfExpertiseRevision: Maybe<TermFieldOfExpertise>;
  loadTermIndustry: Maybe<TermIndustry>;
  loadTermIndustryRevision: Maybe<TermIndustry>;
  loadTermMedicalDomain: Maybe<TermMedicalDomain>;
  loadTermMedicalDomainRevision: Maybe<TermMedicalDomain>;
  loadTermNewsType: Maybe<TermNewsType>;
  loadTermNewsTypeRevision: Maybe<TermNewsType>;
  loadTermParaworkJobType: Maybe<TermParaworkJobType>;
  loadTermParaworkJobTypeRevision: Maybe<TermParaworkJobType>;
  queryContentBlogs: Array<Maybe<ContentBlog>>;
  queryContentBreakingNewss: Array<Maybe<ContentBreakingNews>>;
  queryContentCompanys: Array<Maybe<ContentCompany>>;
  queryContentCompetencess: Array<Maybe<ContentCompetences>>;
  queryContentDoctors: Array<Maybe<ContentDoctor>>;
  queryContentEvents: Array<Maybe<ContentEvent>>;
  queryContentFirstLevelPages: Array<Maybe<ContentFirstLevelPage>>;
  queryContentHistorys: Array<Maybe<ContentHistory>>;
  queryContentKitchenSinks: Array<Maybe<ContentKitchenSink>>;
  queryContentNewss: Array<Maybe<ContentNews>>;
  queryContentSecondLevelPages: Array<Maybe<ContentSecondLevelPage>>;
  queryContentThankYouPages: Array<Maybe<ContentThankYouPage>>;
  queryContentThirdLevelPages: Array<Maybe<ContentThirdLevelPage>>;
  queryContentZsmForms: Array<Maybe<ContentZsmForm>>;
  queryMediaAudios: Array<Maybe<MediaAudio>>;
  queryMediaFiles: Array<Maybe<MediaFile>>;
  queryMediaIcons: Array<Maybe<MediaIcon>>;
  queryMediaImages: Array<Maybe<MediaImage>>;
  queryMediaRemoteVideos: Array<Maybe<MediaRemoteVideo>>;
  queryMediaVideos: Array<Maybe<MediaVideo>>;
  queryMenuFastNavigations: Array<Maybe<MenuFastNavigation>>;
  queryMenuFooterQuickLinksActiveComs: Array<Maybe<MenuFooterQuickLinksActiveCom>>;
  queryMenuFooterQuickLinksGonnerVereinigungs: Array<Maybe<MenuFooterQuickLinksGonnerVereinigung>>;
  queryMenuFooterQuickLinksOrthotecSportss: Array<Maybe<MenuFooterQuickLinksOrthotecSports>>;
  queryMenuFooterQuickLinksOrthotecs: Array<Maybe<MenuFooterQuickLinksOrthotec>>;
  queryMenuFooterQuickLinksParahelps: Array<Maybe<MenuFooterQuickLinksParahelp>>;
  queryMenuFooterQuickLinksSPFs: Array<Maybe<MenuFooterQuickLinksSpf>>;
  queryMenuFooterQuickLinksSPSs: Array<Maybe<MenuFooterQuickLinksSps>>;
  queryMenuFooterQuickLinksSPZs: Array<Maybe<MenuFooterQuickLinksSpz>>;
  queryMenuFooterQuickLinksSirmeds: Array<Maybe<MenuFooterQuickLinksSirmed>>;
  queryMenuFooterQuickLinksSportMedizins: Array<Maybe<MenuFooterQuickLinksSportMedizin>>;
  queryMenuFooters: Array<Maybe<MenuFooter>>;
  queryMenuHomes: Array<Maybe<MenuHome>>;
  queryMenuMains: Array<Maybe<MenuMain>>;
  queryMenuMetaNavigationOrthotecSportss: Array<Maybe<MenuMetaNavigationOrthotecSports>>;
  queryMenuMetaNavigations: Array<Maybe<MenuMetaNavigation>>;
  queryMenuSidebarActiveCommunications: Array<Maybe<MenuSidebarActiveCommunication>>;
  queryMenuSidebarGonnerVereinigungs: Array<Maybe<MenuSidebarGonnerVereinigung>>;
  queryMenuSidebarOrthotecSportss: Array<Maybe<MenuSidebarOrthotecSports>>;
  queryMenuSidebarOrthotecs: Array<Maybe<MenuSidebarOrthotec>>;
  queryMenuSidebarParahelps: Array<Maybe<MenuSidebarParahelp>>;
  queryMenuSidebarSPFs: Array<Maybe<MenuSidebarSpf>>;
  queryMenuSidebarSPSs: Array<Maybe<MenuSidebarSps>>;
  queryMenuSidebarSPZs: Array<Maybe<MenuSidebarSpz>>;
  queryMenuSidebarSirmeds: Array<Maybe<MenuSidebarSirmed>>;
  queryMenuSidebarSportMedizins: Array<Maybe<MenuSidebarSportMedizin>>;
  queryMenuSocialActiveComs: Array<Maybe<MenuSocialActiveCom>>;
  queryMenuSocialOrthotecs: Array<Maybe<MenuSocialOrthotec>>;
  queryMenuSocialParahelps: Array<Maybe<MenuSocialParahelp>>;
  queryMenuSocialSPFs: Array<Maybe<MenuSocialSpf>>;
  queryMenuSocialSPSs: Array<Maybe<MenuSocialSps>>;
  queryMenuSocialSPZs: Array<Maybe<MenuSocialSpz>>;
  queryMenuSocialSirmeds: Array<Maybe<MenuSocialSirmed>>;
  queryMenuSocials: Array<Maybe<MenuSocial>>;
  querySettingsCallToActions: Array<Maybe<SettingsCallToAction>>;
  querySettingsNewsrooms: Array<Maybe<SettingsNewsroom>>;
  querySettingsStorifyMes: Array<Maybe<SettingsStorifyMe>>;
  querySettingsZsmForms: Array<Maybe<SettingsZsmForm>>;
  queryTermBlogCategorys: Array<Maybe<TermBlogCategory>>;
  queryTermBlogOrganisations: Array<Maybe<TermBlogOrganisation>>;
  queryTermCategorys: Array<Maybe<TermCategory>>;
  queryTermDoctorFunctions: Array<Maybe<TermDoctorFunction>>;
  queryTermEventCategorys: Array<Maybe<TermEventCategory>>;
  queryTermFieldOfExpertises: Array<Maybe<TermFieldOfExpertise>>;
  queryTermIndustrys: Array<Maybe<TermIndustry>>;
  queryTermMedicalDomains: Array<Maybe<TermMedicalDomain>>;
  queryTermNewsTypes: Array<Maybe<TermNewsType>>;
  queryTermParaworkJobTypes: Array<Maybe<TermParaworkJobType>>;
};


export type QueryListingCompanyParaworkSearchArgs = {
  argument: InputMaybe<Scalars['String']>;
  filters: InputMaybe<ListingCompanyParaworkSearchFiltersInput>;
  pagination: PaginationInput;
};


export type QueryListingCompanySearchArgs = {
  argument: InputMaybe<Scalars['String']>;
  filters: InputMaybe<ListingCompanySearchFiltersInput>;
  pagination: PaginationInput;
};


export type QueryListingDoctorSearchArgs = {
  argument: InputMaybe<Scalars['String']>;
  filters: InputMaybe<ListingDoctorSearchFiltersInput>;
  pagination: PaginationInput;
};


export type QueryListingMedicalOfferSearchArgs = {
  argument: InputMaybe<Scalars['String']>;
  filters: InputMaybe<ListingMedicalOfferSearchFiltersInput>;
  pagination: PaginationInput;
};


export type QueryListingNewsroomArgs = {
  argument: InputMaybe<Scalars['String']>;
  extras: InputMaybe<ListingNewsroomExtrasInput>;
  filters: InputMaybe<ListingNewsroomFiltersInput>;
  pagination: PaginationInput;
};


export type QueryLoadContentBlogArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentBlogRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentBreakingNewsArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentBreakingNewsRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentCompanyArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentCompanyRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentCompetencesArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentCompetencesRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentDoctorArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentDoctorRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentEventArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentEventRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentFirstLevelPageArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentFirstLevelPageRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentHistoryArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentHistoryRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentKitchenSinkArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentKitchenSinkRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentNewsArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentNewsRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentSecondLevelPageArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentSecondLevelPageRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentThankYouPageArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentThankYouPageRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentThirdLevelPageArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentThirdLevelPageRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadContentZsmFormArgs = {
  id: Scalars['String'];
};


export type QueryLoadContentZsmFormRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadMediaAudioArgs = {
  id: Scalars['String'];
};


export type QueryLoadMediaAudioRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadMediaFileArgs = {
  id: Scalars['String'];
};


export type QueryLoadMediaFileRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadMediaIconArgs = {
  id: Scalars['String'];
};


export type QueryLoadMediaIconRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadMediaImageArgs = {
  id: Scalars['String'];
};


export type QueryLoadMediaImageRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadMediaRemoteVideoArgs = {
  id: Scalars['String'];
};


export type QueryLoadMediaRemoteVideoRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadMediaVideoArgs = {
  id: Scalars['String'];
};


export type QueryLoadMediaVideoRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadMenuFastNavigationArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuFooterArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuFooterQuickLinksActiveComArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuFooterQuickLinksGonnerVereinigungArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuFooterQuickLinksOrthotecArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuFooterQuickLinksOrthotecSportsArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuFooterQuickLinksParahelpArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuFooterQuickLinksSpfArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuFooterQuickLinksSpsArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuFooterQuickLinksSpzArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuFooterQuickLinksSirmedArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuFooterQuickLinksSportMedizinArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuHomeArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuMainArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuMetaNavigationArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuMetaNavigationOrthotecSportsArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSidebarActiveCommunicationArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSidebarGonnerVereinigungArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSidebarOrthotecArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSidebarOrthotecSportsArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSidebarParahelpArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSidebarSpfArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSidebarSpsArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSidebarSpzArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSidebarSirmedArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSidebarSportMedizinArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSocialArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSocialActiveComArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSocialOrthotecArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSocialParahelpArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSocialSpfArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSocialSpsArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSocialSpzArgs = {
  id: Scalars['String'];
};


export type QueryLoadMenuSocialSirmedArgs = {
  id: Scalars['String'];
};


export type QueryLoadSettingsCallToActionArgs = {
  id: Scalars['String'];
};


export type QueryLoadSettingsCallToActionRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadSettingsNewsroomArgs = {
  id: Scalars['String'];
};


export type QueryLoadSettingsNewsroomRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadSettingsStorifyMeArgs = {
  id: Scalars['String'];
};


export type QueryLoadSettingsStorifyMeRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadSettingsZsmFormArgs = {
  id: Scalars['String'];
};


export type QueryLoadSettingsZsmFormRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadTermBlogCategoryArgs = {
  id: Scalars['String'];
};


export type QueryLoadTermBlogCategoryRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadTermBlogOrganisationArgs = {
  id: Scalars['String'];
};


export type QueryLoadTermBlogOrganisationRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadTermCategoryArgs = {
  id: Scalars['String'];
};


export type QueryLoadTermCategoryRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadTermDoctorFunctionArgs = {
  id: Scalars['String'];
};


export type QueryLoadTermDoctorFunctionRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadTermEventCategoryArgs = {
  id: Scalars['String'];
};


export type QueryLoadTermEventCategoryRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadTermFieldOfExpertiseArgs = {
  id: Scalars['String'];
};


export type QueryLoadTermFieldOfExpertiseRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadTermIndustryArgs = {
  id: Scalars['String'];
};


export type QueryLoadTermIndustryRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadTermMedicalDomainArgs = {
  id: Scalars['String'];
};


export type QueryLoadTermMedicalDomainRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadTermNewsTypeArgs = {
  id: Scalars['String'];
};


export type QueryLoadTermNewsTypeRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryLoadTermParaworkJobTypeArgs = {
  id: Scalars['String'];
};


export type QueryLoadTermParaworkJobTypeRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type QueryQueryContentBlogsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentBreakingNewssArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentCompanysArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentCompetencessArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentDoctorsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentEventsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentFirstLevelPagesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentHistorysArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentKitchenSinksArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentNewssArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentSecondLevelPagesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentThankYouPagesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentThirdLevelPagesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryContentZsmFormsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMediaAudiosArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMediaFilesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMediaIconsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMediaImagesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMediaRemoteVideosArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMediaVideosArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFastNavigationsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFooterQuickLinksActiveComsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFooterQuickLinksGonnerVereinigungsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFooterQuickLinksOrthotecSportssArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFooterQuickLinksOrthotecsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFooterQuickLinksParahelpsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFooterQuickLinksSpFsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFooterQuickLinksSpSsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFooterQuickLinksSpZsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFooterQuickLinksSirmedsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFooterQuickLinksSportMedizinsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuFootersArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuHomesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuMainsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuMetaNavigationOrthotecSportssArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuMetaNavigationsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSidebarActiveCommunicationsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSidebarGonnerVereinigungsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSidebarOrthotecSportssArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSidebarOrthotecsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSidebarParahelpsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSidebarSpFsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSidebarSpSsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSidebarSpZsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSidebarSirmedsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSidebarSportMedizinsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSocialActiveComsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSocialOrthotecsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSocialParahelpsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSocialSpFsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSocialSpSsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSocialSpZsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSocialSirmedsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryMenuSocialsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQuerySettingsCallToActionsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQuerySettingsNewsroomsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQuerySettingsStorifyMesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQuerySettingsZsmFormsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryTermBlogCategorysArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryTermBlogOrganisationsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryTermCategorysArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryTermDoctorFunctionsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryTermEventCategorysArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryTermFieldOfExpertisesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryTermIndustrysArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryTermMedicalDomainsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryTermNewsTypesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryQueryTermParaworkJobTypesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};

export type SettingsCallToAction = {
  __typename?: 'SettingsCallToAction';
  _original_typename: Scalars['String'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  primaryButtonLink: Maybe<LinkWithTranslations>;
  primaryButtonType: Scalars['String'];
  secondaryButtonLink: Maybe<LinkWithTranslations>;
  secondaryButtonType: Maybe<Scalars['String']>;
};

export type SettingsNewsroom = {
  __typename?: 'SettingsNewsroom';
  _original_typename: Scalars['String'];
  buttonLink: Maybe<LinkWithTranslations>;
  buttonTextEnglish: Scalars['String'];
  buttonTextFrench: Scalars['String'];
  buttonTextGerman: Scalars['String'];
  buttonTextItalian: Scalars['String'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  newsroomLink: Maybe<LinkWithTranslations>;
};

export type SettingsStorifyMe = {
  __typename?: 'SettingsStorifyMe';
  _original_typename: Scalars['String'];
  accountId: Scalars['String'];
  drupalId: Scalars['String'];
  environment: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type SettingsZsmForm = {
  __typename?: 'SettingsZsmForm';
  _original_typename: Scalars['String'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  thankYouPageLink: Maybe<LinkWithTranslations>;
};

export type TermBlogCategory = {
  __typename?: 'TermBlogCategory';
  _original_typename: Scalars['String'];
  alternateTitle: Maybe<Scalars['String']>;
  defaultTranslation: Scalars['Boolean'];
  descriptionHtml: Maybe<Scalars['String']>;
  drupalId: Scalars['String'];
  headerImage: Maybe<MediaImage>;
  id: Scalars['String'];
  langcode: Scalars['String'];
  path: Scalars['String'];
  title: Scalars['String'];
  translations: Array<TermBlogCategory>;
};

export type TermBlogOrganisation = {
  __typename?: 'TermBlogOrganisation';
  _original_typename: Scalars['String'];
  alternateTitle: Maybe<Scalars['String']>;
  defaultTranslation: Scalars['Boolean'];
  descriptionHtml: Maybe<Scalars['String']>;
  drupalId: Scalars['String'];
  headerImage: Maybe<MediaImage>;
  id: Scalars['String'];
  langcode: Scalars['String'];
  path: Scalars['String'];
  title: Scalars['String'];
  translations: Array<TermBlogOrganisation>;
};

export type TermCategory = {
  __typename?: 'TermCategory';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  title: Scalars['String'];
  translations: Array<TermCategory>;
};

export type TermDoctorFunction = {
  __typename?: 'TermDoctorFunction';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  title: Scalars['String'];
  translations: Array<TermDoctorFunction>;
};

export type TermEventCategory = {
  __typename?: 'TermEventCategory';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  title: Scalars['String'];
  translations: Array<TermEventCategory>;
};

export type TermFieldOfExpertise = {
  __typename?: 'TermFieldOfExpertise';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  title: Scalars['String'];
  translations: Array<TermFieldOfExpertise>;
};

export type TermIndustry = {
  __typename?: 'TermIndustry';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  title: Scalars['String'];
  translations: Array<TermIndustry>;
};

export type TermMedicalDomain = {
  __typename?: 'TermMedicalDomain';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  title: Scalars['String'];
  translations: Array<TermMedicalDomain>;
};

export type TermNewsType = {
  __typename?: 'TermNewsType';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  title: Scalars['String'];
  translations: Array<TermNewsType>;
  weight: Maybe<Scalars['Int']>;
};

export type TermParaworkJobType = {
  __typename?: 'TermParaworkJobType';
  _original_typename: Scalars['String'];
  defaultTranslation: Scalars['Boolean'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  title: Scalars['String'];
  translations: Array<TermParaworkJobType>;
};

export type TranslatedLink = {
  __typename?: 'TranslatedLink';
  _original_typename: Scalars['String'];
  langcode: Maybe<Scalars['String']>;
  link: Maybe<Link>;
};

export type User = {
  __typename?: 'User';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type ContentFirstLevelPageBreadcrumbsQueryVariables = Exact<{
  remoteId: Scalars['String'];
}>;


export type ContentFirstLevelPageBreadcrumbsQuery = { __typename?: 'Query', loadContentFirstLevelPage: { __typename?: 'ContentFirstLevelPage', breadcrumbs: Array<{ __typename?: 'BreadcrumbLink', url: string | undefined, text: string } | undefined> | undefined } | undefined };

export type ContentSecondLevelPageBreadcrumbsQueryVariables = Exact<{
  remoteId: Scalars['String'];
}>;


export type ContentSecondLevelPageBreadcrumbsQuery = { __typename?: 'Query', loadContentSecondLevelPage: { __typename?: 'ContentSecondLevelPage', breadcrumbs: Array<{ __typename?: 'BreadcrumbLink', url: string | undefined, text: string } | undefined> | undefined } | undefined };

export type ContentThirdLevelPageBreadcrumbsQueryVariables = Exact<{
  remoteId: Scalars['String'];
}>;


export type ContentThirdLevelPageBreadcrumbsQuery = { __typename?: 'Query', loadContentThirdLevelPage: { __typename?: 'ContentThirdLevelPage', breadcrumbs: Array<{ __typename?: 'BreadcrumbLink', url: string | undefined, text: string } | undefined> | undefined } | undefined };

export type ListingCompanyParaworkSearchQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  language: InputMaybe<Scalars['String']>;
  text: InputMaybe<Scalars['String']>;
  glossary: InputMaybe<Scalars['String']>;
  industry: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  jobType: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ListingCompanyParaworkSearchQuery = { __typename?: 'Query', listingCompanyParaworkSearch: { __typename?: 'ListingResultContentCompany', total: number, items: Array<{ __typename?: 'ContentCompany', drupalId: string, path: string, title: string, email: string | undefined, place: string, details: string | undefined, zip: string | undefined, streetAndNumber: string, telephone: string | undefined, paraworkJobCompany: boolean | undefined, hasContent: boolean | undefined, paraworkJobType: Array<{ __typename?: 'TermParaworkJobType', title: string, id: string } | undefined>, websiteLink: { __typename?: 'Link', title: string, url: string } | undefined, logo: { __typename?: 'MediaImage', styles: Array<{ __typename?: 'ImageStyle', id: string, url: string } | undefined> } | undefined } | undefined> } };

export type ListingCompanySearchQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  language: InputMaybe<Scalars['String']>;
  text: InputMaybe<Scalars['String']>;
  glossary: InputMaybe<Scalars['String']>;
  industry: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ListingCompanySearchQuery = { __typename?: 'Query', listingCompanySearch: { __typename?: 'ListingResultContentCompany', total: number, items: Array<{ __typename?: 'ContentCompany', drupalId: string, path: string, title: string, email: string | undefined, place: string, details: string | undefined, zip: string | undefined, streetAndNumber: string, telephone: string | undefined, paraworkJobCompany: boolean | undefined, hasContent: boolean | undefined, paraworkJobType: Array<{ __typename?: 'TermParaworkJobType', title: string, id: string } | undefined>, websiteLink: { __typename?: 'Link', title: string, url: string } | undefined, logo: { __typename?: 'MediaImage', styles: Array<{ __typename?: 'ImageStyle', id: string, url: string } | undefined> } | undefined } | undefined> } };

export type ListingDoctorSearchQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  language: InputMaybe<Scalars['String']>;
  glossary: InputMaybe<Scalars['String']>;
  field: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  doctorFunction: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ListingDoctorSearchQuery = { __typename?: 'Query', listingDoctorSearch: { __typename?: 'ListingResultDoctor', total: number, items: Array<{ __typename?: 'ContentDoctor', path: string, firstName: string, lastName: string, job: string | undefined, additionalInformation: string | undefined, specialistDepartment: string | undefined, specialistTeaserTitle: string | undefined, email: string | undefined, telephone: string | undefined, fax: string | undefined, drupalId: string, title: string, additionalFields: Array<{ __typename?: 'TermFieldOfExpertise', title: string, id: string } | undefined>, doctorFunction: { __typename?: 'TermDoctorFunction', title: string, id: string } | undefined, mainField: { __typename?: 'TermFieldOfExpertise', title: string, id: string }, teaserImage: { __typename: 'MediaImage', url: string, width: number | undefined, height: number | undefined, alt: string | undefined, title: string | undefined, mimeType: string | undefined, styles: Array<{ __typename?: 'ImageStyle', id: string, url: string, width: number | undefined, height: number | undefined } | undefined> } | undefined, domainAccess: Array<{ __typename?: 'Domain', id: string } | undefined> } | undefined> } };

export type ListingMedicalOfferSearchQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  language: InputMaybe<Scalars['String']>;
  glossary: InputMaybe<Scalars['String']>;
  medicalDomain: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ListingMedicalOfferSearchQuery = { __typename?: 'Query', listingMedicalOfferSearch: { __typename?: 'ListingResultMedicalOffer', total: number, items: Array<{ __typename?: 'ContentSecondLevelPage', drupalId: string, path: string, title: string, domainAccess: Array<{ __typename?: 'Domain', id: string } | undefined> } | undefined> } };

export type ListingNewsroomQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  newsType: Array<Scalars['String']> | Scalars['String'];
  organisation: InputMaybe<Scalars['String']>;
  highlightedCount: InputMaybe<Scalars['Int']>;
  language: InputMaybe<Scalars['String']>;
}>;


export type ListingNewsroomQuery = { __typename?: 'Query', listingNewsroom: { __typename?: 'ListingResultNewsroom', total: number, items: Array<{ __typename: 'ContentBlog', path: string, title: string, langcode: string, created: number | undefined, sticky: boolean | undefined, newsType: Array<{ __typename?: 'TermNewsType', title: string } | undefined>, domainAccess: Array<{ __typename?: 'Domain', id: string } | undefined>, teaserImage: { __typename: 'MediaImage', url: string, width: number | undefined, height: number | undefined, alt: string | undefined, title: string | undefined, mimeType: string | undefined, styles: Array<{ __typename?: 'ImageStyle', id: string, url: string, width: number | undefined, height: number | undefined } | undefined> } | undefined } | { __typename: 'ContentNews', path: string, title: string, date: string | undefined, langcode: string, sticky: boolean | undefined, newsType: Array<{ __typename?: 'TermNewsType', title: string } | undefined>, domainAccess: Array<{ __typename?: 'Domain', id: string } | undefined> } | undefined> | undefined } };


export const ContentFirstLevelPageBreadcrumbsDocument = `
    query ContentFirstLevelPageBreadcrumbs($remoteId: String!) {
  loadContentFirstLevelPage(id: $remoteId) {
    breadcrumbs {
      text: title
      url
    }
  }
}
    `;
export const useContentFirstLevelPageBreadcrumbsQuery = <
      TData = ContentFirstLevelPageBreadcrumbsQuery,
      TError = unknown
    >(
      variables: ContentFirstLevelPageBreadcrumbsQueryVariables,
      options?: UseQueryOptions<ContentFirstLevelPageBreadcrumbsQuery, TError, TData>
    ) =>
    useQuery<ContentFirstLevelPageBreadcrumbsQuery, TError, TData>(
      ['ContentFirstLevelPageBreadcrumbs', variables],
      fetcher<ContentFirstLevelPageBreadcrumbsQuery, ContentFirstLevelPageBreadcrumbsQueryVariables>(ContentFirstLevelPageBreadcrumbsDocument, variables),
      options
    );
export const ContentSecondLevelPageBreadcrumbsDocument = `
    query ContentSecondLevelPageBreadcrumbs($remoteId: String!) {
  loadContentSecondLevelPage(id: $remoteId) {
    breadcrumbs {
      text: title
      url
    }
  }
}
    `;
export const useContentSecondLevelPageBreadcrumbsQuery = <
      TData = ContentSecondLevelPageBreadcrumbsQuery,
      TError = unknown
    >(
      variables: ContentSecondLevelPageBreadcrumbsQueryVariables,
      options?: UseQueryOptions<ContentSecondLevelPageBreadcrumbsQuery, TError, TData>
    ) =>
    useQuery<ContentSecondLevelPageBreadcrumbsQuery, TError, TData>(
      ['ContentSecondLevelPageBreadcrumbs', variables],
      fetcher<ContentSecondLevelPageBreadcrumbsQuery, ContentSecondLevelPageBreadcrumbsQueryVariables>(ContentSecondLevelPageBreadcrumbsDocument, variables),
      options
    );
export const ContentThirdLevelPageBreadcrumbsDocument = `
    query ContentThirdLevelPageBreadcrumbs($remoteId: String!) {
  loadContentThirdLevelPage(id: $remoteId) {
    breadcrumbs {
      text: title
      url
    }
  }
}
    `;
export const useContentThirdLevelPageBreadcrumbsQuery = <
      TData = ContentThirdLevelPageBreadcrumbsQuery,
      TError = unknown
    >(
      variables: ContentThirdLevelPageBreadcrumbsQueryVariables,
      options?: UseQueryOptions<ContentThirdLevelPageBreadcrumbsQuery, TError, TData>
    ) =>
    useQuery<ContentThirdLevelPageBreadcrumbsQuery, TError, TData>(
      ['ContentThirdLevelPageBreadcrumbs', variables],
      fetcher<ContentThirdLevelPageBreadcrumbsQuery, ContentThirdLevelPageBreadcrumbsQueryVariables>(ContentThirdLevelPageBreadcrumbsDocument, variables),
      options
    );
export const ListingCompanyParaworkSearchDocument = `
    query ListingCompanyParaworkSearch($offset: Int!, $limit: Int!, $language: String, $text: String, $glossary: String, $industry: [String!], $jobType: [String!]) {
  listingCompanyParaworkSearch(
    pagination: {offset: $offset, limit: $limit}
    filters: {language: $language, text: $text, glossary: $glossary, industry: $industry, paraworkJobType: $jobType}
  ) {
    total
    items {
      drupalId
      path
      title
      email
      place
      details
      zip
      streetAndNumber
      telephone
      paraworkJobType {
        title
        id
      }
      paraworkJobCompany
      hasContent
      websiteLink {
        title
        url
      }
      logo {
        styles {
          id
          url
        }
      }
    }
  }
}
    `;
export const useListingCompanyParaworkSearchQuery = <
      TData = ListingCompanyParaworkSearchQuery,
      TError = unknown
    >(
      variables: ListingCompanyParaworkSearchQueryVariables,
      options?: UseQueryOptions<ListingCompanyParaworkSearchQuery, TError, TData>
    ) =>
    useQuery<ListingCompanyParaworkSearchQuery, TError, TData>(
      ['ListingCompanyParaworkSearch', variables],
      fetcher<ListingCompanyParaworkSearchQuery, ListingCompanyParaworkSearchQueryVariables>(ListingCompanyParaworkSearchDocument, variables),
      options
    );
export const ListingCompanySearchDocument = `
    query ListingCompanySearch($offset: Int!, $limit: Int!, $language: String, $text: String, $glossary: String, $industry: [String!]) {
  listingCompanySearch(
    pagination: {offset: $offset, limit: $limit}
    filters: {language: $language, text: $text, glossary: $glossary, industry: $industry}
  ) {
    total
    items {
      drupalId
      path
      title
      email
      place
      details
      zip
      streetAndNumber
      telephone
      paraworkJobType {
        title
        id
      }
      paraworkJobCompany
      hasContent
      websiteLink {
        title
        url
      }
      logo {
        styles {
          id
          url
        }
      }
    }
  }
}
    `;
export const useListingCompanySearchQuery = <
      TData = ListingCompanySearchQuery,
      TError = unknown
    >(
      variables: ListingCompanySearchQueryVariables,
      options?: UseQueryOptions<ListingCompanySearchQuery, TError, TData>
    ) =>
    useQuery<ListingCompanySearchQuery, TError, TData>(
      ['ListingCompanySearch', variables],
      fetcher<ListingCompanySearchQuery, ListingCompanySearchQueryVariables>(ListingCompanySearchDocument, variables),
      options
    );
export const ListingDoctorSearchDocument = `
    query ListingDoctorSearch($offset: Int!, $limit: Int!, $language: String, $glossary: String, $field: [String!], $doctorFunction: [String!]) {
  listingDoctorSearch(
    pagination: {offset: $offset, limit: $limit}
    filters: {language: $language, glossary: $glossary, field: $field, doctorFunction: $doctorFunction}
  ) {
    total
    items {
      drupalId
      path
      title
      domainAccess {
        id
      }
      ... on ContentDoctor {
        path
        firstName
        lastName
        job
        additionalInformation
        specialistDepartment
        specialistTeaserTitle
        additionalFields {
          title
          id
        }
        doctorFunction {
          title
          id
        }
        mainField {
          title
          id
        }
        email
        telephone
        fax
        teaserImage {
          ... on MediaImage {
            __typename
            url
            styles {
              id
              url
              width
              height
            }
            width
            height
            alt
            title
            mimeType
          }
        }
      }
    }
  }
}
    `;
export const useListingDoctorSearchQuery = <
      TData = ListingDoctorSearchQuery,
      TError = unknown
    >(
      variables: ListingDoctorSearchQueryVariables,
      options?: UseQueryOptions<ListingDoctorSearchQuery, TError, TData>
    ) =>
    useQuery<ListingDoctorSearchQuery, TError, TData>(
      ['ListingDoctorSearch', variables],
      fetcher<ListingDoctorSearchQuery, ListingDoctorSearchQueryVariables>(ListingDoctorSearchDocument, variables),
      options
    );
export const ListingMedicalOfferSearchDocument = `
    query ListingMedicalOfferSearch($offset: Int!, $limit: Int!, $language: String, $glossary: String, $medicalDomain: [String!]) {
  listingMedicalOfferSearch(
    pagination: {offset: $offset, limit: $limit}
    filters: {language: $language, glossary: $glossary, medicalDomain: $medicalDomain}
  ) {
    total
    items {
      drupalId
      path
      title
      domainAccess {
        id
      }
    }
  }
}
    `;
export const useListingMedicalOfferSearchQuery = <
      TData = ListingMedicalOfferSearchQuery,
      TError = unknown
    >(
      variables: ListingMedicalOfferSearchQueryVariables,
      options?: UseQueryOptions<ListingMedicalOfferSearchQuery, TError, TData>
    ) =>
    useQuery<ListingMedicalOfferSearchQuery, TError, TData>(
      ['ListingMedicalOfferSearch', variables],
      fetcher<ListingMedicalOfferSearchQuery, ListingMedicalOfferSearchQueryVariables>(ListingMedicalOfferSearchDocument, variables),
      options
    );
export const ListingNewsroomDocument = `
    query ListingNewsroom($offset: Int!, $limit: Int!, $newsType: [String!]!, $organisation: String, $highlightedCount: Int, $language: String) {
  listingNewsroom(
    pagination: {offset: $offset, limit: $limit}
    filters: {organisation: $organisation, language: $language, news_type: $newsType}
    extras: {highlightedCount: $highlightedCount}
  ) {
    total
    items {
      __typename
      ... on NodeInterface {
        path
      }
      ... on ContentNews {
        title
        date
        langcode
        sticky
        newsType {
          title
        }
        domainAccess {
          id
        }
      }
      ... on ContentBlog {
        title
        langcode
        created
        sticky
        newsType {
          title
        }
        domainAccess {
          id
        }
        teaserImage {
          __typename
          url
          styles {
            id
            url
            width
            height
          }
          width
          height
          alt
          title
          mimeType
        }
      }
    }
  }
}
    `;
export const useListingNewsroomQuery = <
      TData = ListingNewsroomQuery,
      TError = unknown
    >(
      variables: ListingNewsroomQueryVariables,
      options?: UseQueryOptions<ListingNewsroomQuery, TError, TData>
    ) =>
    useQuery<ListingNewsroomQuery, TError, TData>(
      ['ListingNewsroom', variables],
      fetcher<ListingNewsroomQuery, ListingNewsroomQueryVariables>(ListingNewsroomDocument, variables),
      options
    );
import { ParagraphTeaserNewsroom } from '../../../generated/queries/preview-queries';

export const transformParagraphViewsNewsroomTeaserToProps = (
  paragraph: ParagraphTeaserNewsroom,
) => {
  const maxItems = parseInt(paragraph?.maxItems || '4');

  return {
    title: paragraph.pTitle,
    domain: paragraph.domain,
    contentTypes: paragraph.contentTypes as string[],
    linkText: paragraph.linkText,
    itemsPerPage: maxItems < 4 ? 4 : maxItems,
  };
};

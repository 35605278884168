import { ContentKitchenSink } from '../../../generated/queries/preview-queries';

export const transformKitchenSinkFragmentToKitchenSinkPage = (
  kitchenSink: ContentKitchenSink,
) => {
  return {
    title: kitchenSink.title,
    lead: '',
    hideFundingBanner: true,
    date: undefined,
  };
};

import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GIFT_INDIVIDUAL_MEMBER,
  GIFT_MARRIED_AND_FAMILIES,
  GIFT_PERMANENT_MEMBER,
  INDIVIDUAL_MEMBER,
  MARRIED_AND_FAMILIES,
  PERMANENT_MEMBER,
} from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { useRaisenowTrackingWithBuildUrl } from '../../utils';
import { IconKey, IconSvg } from '../0-atoms';
import { FadeUp } from '../1-molecules/FadeIn';

export type MembershipWidgetProps = {
  className?: string;
  displayMode?: string;
  inHeader?: boolean;
  isGift?: boolean;
};

export const MembershipWidget: React.FC<MembershipWidgetProps> = ({
  className,
  displayMode,
  inHeader = false,
  isGift = false,
}) => {
  const { t } = useTranslation();
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const defaultId = 'individual-member';
  const amounts = [
    {
      amount: 45,
      currency: 'CHF',
      title: t('Individual member'),
      occurrence: t('per year'),
      id: 'individual-member',
      url: useRaisenowTrackingWithBuildUrl([
        '/',
        locale,
        'node',
        isGift
          ? GIFT_INDIVIDUAL_MEMBER.toString()
          : INDIVIDUAL_MEMBER.toString(),
      ]),
    },
    {
      amount: 90,
      currency: 'CHF',
      title: t('Married and families'),
      occurrence: t('per year'),
      id: 'married-and-families',
      url: useRaisenowTrackingWithBuildUrl([
        '/',
        locale,
        'node',
        isGift
          ? GIFT_MARRIED_AND_FAMILIES.toString()
          : MARRIED_AND_FAMILIES.toString(),
      ]),
    },
    {
      amount: 1000,
      currency: 'CHF',
      title: t('Permanent member'),
      occurrence: t('once'),
      id: 'permanent-member',
      url: useRaisenowTrackingWithBuildUrl([
        '/',
        locale,
        'node',
        isGift ? GIFT_PERMANENT_MEMBER.toString() : PERMANENT_MEMBER.toString(),
      ]),
    },
  ];

  const [membershipSetId, setMembershipSetId] = useState(defaultId);
  const [membershipType, setMembershipType] = useState(amounts[0].title);
  const widgetDisplayMode = displayMode || 'compact';
  const dataLayerWidgetDisplayMode = displayMode
    ? displayMode
    : inHeader
    ? 'header'
    : 'compact';
  const randomKey = Math.random().toString(36).substring(7);
  const [isVisible, setIsVisible] = useState(false);
  const formMembership = useRef<HTMLDivElement>(null);
  // Convert the id to the action url
  const getAction = (id: string) => {
    return amounts.find((amount) => amount.id === id)?.url;
  };

  //a hack around the form action not updating with the search params in url changing url in time on building is different than the url when the page is loaded
  // this state is used as a key to the form to force the form to re-render
  //we should find a better solution for this
  const [defaultURL, setDefaultURL] = useState('');
  useEffect(() => {
    setDefaultURL(getAction(defaultId) ?? '');
  }, [getAction(defaultId)]);

  useEffect(() => {
    // Send a dataLayer push when the component becomes visible on the screen.
    const dataLayerOnView = {
      event: 'conversionwidget_view',
      goal: isGift ? 'membership gift' : 'membership',
      format: dataLayerWidgetDisplayMode,
      lang: locale,
    };
    if (isVisible) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataLayerOnView);
    }
  }, [dataLayerWidgetDisplayMode, isVisible, locale]);

  useEffect(() => {
    if (!formMembership.current) {
      return;
    }
    // Create an Intersection Observer to track component visibility.
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { rootMargin: '0px' }, // You can adjust the root margin if needed.
    );

    // Start observing the component's root element.
    observer.observe(formMembership.current);

    // Cleanup the observer when the component unmounts.
    return () => observer.disconnect();
  }, []);

  return (
    <FadeUp yGap={20}>
      <div
        className={classNames(
          'MembershipWidget',
          'FormCard--wrapper',
          `FormCard--wrapper__${widgetDisplayMode}`,
        )}
        ref={formMembership}
      >
        <form
          action={getAction(membershipSetId)}
          method="POST"
          key={defaultURL}
          className={classNames(
            'FormCard',
            className,
            `FormCard__${widgetDisplayMode}`,
            isGift
              ? `FormCard__gift__${widgetDisplayMode}`
              : `FormCard__membership__${widgetDisplayMode}`,
          )}
          onSubmit={() => {
            const dataLayerOnSubmit = {
              event: 'conversionwidget_submit',
              goal: isGift ? 'membership gift' : 'membership',
              format: dataLayerWidgetDisplayMode,
              lang: locale,
              type: membershipType,
            };
            // Send the selected amount to the dataLayer before submitting the form.
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(dataLayerOnSubmit);
          }}
        >
          <div className="FormCard--body">
            <div className="FormCard--header">
              {widgetDisplayMode === 'compact' && (
                <>
                  <h2
                    className="FormCard--title"
                    dangerouslySetInnerHTML={{
                      __html: isGift
                        ? t('<em>Give the gift</em> of membership')
                        : t('<em>Become</em> a member'),
                    }}
                  />
                  <p className="FormCard--note">
                    {isGift
                      ? t(
                          'Your loved ones will receive 250,000 francs should the unthinkable happen.',
                        )
                      : t('And receive 250,000 francs in an emergency.')}
                  </p>
                </>
              )}
              {widgetDisplayMode === 'full_width' && (
                <>
                  <h2 className="ArticleTitle--title h3">
                    {t('CHF 250,000 if the unthinkable happens')}
                  </h2>
                  <h3 className="h1">
                    {isGift
                      ? t('A useful gift for your loved ones')
                      : t('Protect yourself - become a member now')}
                  </h3>
                  <p>
                    {isGift
                      ? t(
                          'Giving a membership will provide joy and show you care.',
                        )
                      : t(
                          '1.9 million members have already put their trust in the Swiss Paraplegic Foundation.',
                        )}
                  </p>
                </>
              )}
            </div>
            <div className="FormCard--actions">
              <p className="visuallyhidden">{t('Choose membership')}</p>
              <ul className="FormCard--list">
                {amounts &&
                  amounts.map((value, index) => (
                    <li
                      key={index}
                      className={classNames(
                        'FormCard--list--option',
                        membershipSetId === value.id
                          ? 'FormCard--list--option__selected'
                          : '',
                      )}
                    >
                      <div className="FormCard--option">
                        <input
                          className="FormCard--option--input"
                          id={`FormCard-option-${index}-${randomKey}`}
                          name="optionu1"
                          type="radio"
                          value={value.id}
                          checked={membershipSetId === value.id}
                          onChange={() => {
                            setMembershipSetId(value.id);
                            setMembershipType(value.title);
                          }}
                        />
                        <label
                          className="FormCard--option--label"
                          htmlFor={`FormCard-option-${index}-${randomKey}`}
                        >
                          <div className="FormCard--option--label--details FormCard--option--label--not-compact">
                            <div className="FormCard--option--label--title">
                              {value.title}
                            </div>
                            <div className="FormCard--option--amount">
                              <span className="FormCard--option--amount--left">
                                <span className="FormCard--option--amount--text">
                                  {value.occurrence}
                                </span>
                                <span className="FormCard--option--amount--currency">
                                  {value.currency}
                                </span>
                              </span>
                              <span className="FormCard--option--amount--right">
                                <span className="FormCard--option--amount--value">
                                  {value.amount}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div className="FormCard--option--label--details FormCard--option--label--compact">
                            <div className="FormCard--option--label--compact--left">
                              <div className="FormCard--option--label--title">
                                {value.title}
                              </div>
                              <span className="FormCard--option--amount--text">
                                {value.occurrence}
                              </span>
                            </div>

                            <div className="FormCard--option--label--compact--right">
                              <span className="FormCard--option--amount--value">
                                {value.amount}
                              </span>
                              <span className="FormCard--option--amount--currency">
                                {value.currency}
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </li>
                  ))}
                <li className="FormCard--list--submit">
                  <div className="FormCard--submit">
                    <button
                      className={classNames(
                        'icon-before',
                        'FormCard--button',
                        'FormCard--button-submit',
                      )}
                    >
                      <i
                        className={classNames(
                          'before',
                          'ico',
                          isGift ? 'ico-gift-white' : 'ico-membership-white',
                          'inline',
                        )}
                      />
                      <span className="Button--text">
                        {isGift ? t('Gift now') : t('Become a member')}
                      </span>
                    </button>
                  </div>
                </li>
                {widgetDisplayMode === 'full_width' && (
                  <li className="FormCard--list--secure">
                    <div className="FormCard--secure--wrapper">
                      <div className="FormCard--secure">
                        <IconSvg
                          wrapClass={'icon--before'}
                          icon={`ico-lock` as IconKey}
                        />
                        <p>{t('Your transaction is secure')}</p>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </form>
      </div>
    </FadeUp>
  );
};

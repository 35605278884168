import {
  ParagraphAccordionDownloadItem,
  ParagraphAccordionLinkItem,
  ParagraphAccordionSimpleItem,
} from '../../../generated/queries/preview-queries';

export const isAccordionItemLink = (
  item?:
    | ParagraphAccordionDownloadItem
    | ParagraphAccordionSimpleItem
    | ParagraphAccordionLinkItem,
): item is ParagraphAccordionLinkItem =>
  item?.__typename === 'ParagraphAccordionLinkItem';

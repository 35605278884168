import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { useLocalization } from '../../hooks';
import { Icon } from '../../types';
import { getTranslatedUrl } from '../../utils/getTranslatedUrl';
import { CTAButton, CTAButtonProps } from '../0-atoms';

export type CTAMediaRequestButtonProps = Omit<
  CTAButtonProps,
  'icon' | 'label' | 'url'
>;

export const CTAMediaRequestButton: React.FC<CTAMediaRequestButtonProps> = ({
  ...props
}) => {
  const settings = useStaticQuery(graphql`
    query SettingsNewsroomMediaRequestDetails {
      drupalSettingsNewsroom {
        buttonTextEnglish
        buttonTextGerman
        buttonTextFrench
        buttonTextItalian
        buttonLink {
          translations {
            langcode
            link {
              url
            }
          }
        }
      }
    }
  `);

  let buttonText = 'Media request';

  const { locale: currentLocale } = useLocalization();
  switch (currentLocale) {
    case 'en':
      buttonText = settings.drupalSettingsNewsroom?.buttonTextEnglish || '';
      break;
    case 'de':
      buttonText = settings.drupalSettingsNewsroom?.buttonTextGerman || '';
      break;
    case 'fr':
      buttonText = settings.drupalSettingsNewsroom?.buttonTextFrench || '';
      break;
    case 'it':
      buttonText = settings.drupalSettingsNewsroom?.buttonTextItalian || '';
      break;
  }
  const buttonUrl = getTranslatedUrl({
    urlObject: settings.drupalSettingsNewsroom?.buttonLink?.translations || [],
    langcode: currentLocale,
  }) as unknown as string;

  return (
    <CTAButton
      icon={Icon.Ad}
      className="media-request"
      label={buttonText}
      url={buttonUrl}
      {...props}
    />
  );
};

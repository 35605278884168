import { ParagraphLinkList } from '../../../generated/queries/preview-queries';
import { isPublished } from '../../utils';

export const transformParagraphLinkListToProps = (
  paragraph: ParagraphLinkList,
) => ({
  links:
    paragraph.links?.filter(isPublished).map((link) => ({
      url: link?.url || '',
      label: link?.title || '',
      target: link?.options?.attributes?.target,
    })) || [],
});

import { Locale } from '../../i18n';
import { useDataDependencies, useFrameworkDependencies } from '../dependencies';
import { getNodesByLanguage } from '../utils';

export const useIndustryTerms = (locale?: Locale) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const { useIndustryTermsQuery } = useDataDependencies();
  const terms = useIndustryTermsQuery();

  return getNodesByLanguage<
    IndustryTermsQuery['allDrupalTermIndustry']['group'][0]['nodes'][0]
  >(locale || currentLocale)(terms.allDrupalTermIndustry);
};

import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadataQuery = () =>
  useStaticQuery<SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentCompany,
  useListingCompanyParaworkSearchQuery,
} from '../../../generated/queries/react-queries';
import { getUrlFromStyles } from '../../fragments/mappers';
import {
  useCompaniesParaworkFilters,
  useIndustryTerms,
  useLocalization,
  usePager,
  useParaworkJobTypeTerms,
} from '../../hooks';
import { isTruthy } from '../../utils/isTruthy';
import { Loader } from '../0-atoms';
import { CompaniesParaworkFilterForm, CompanyTeaserList } from '../1-molecules';
import { Pager } from './Pager';

export type CompaniesParaworkViewProps = {
  itemsPerPage?: number;
  className?: string;
};

export const CompaniesParaworkView: React.FC<CompaniesParaworkViewProps> = ({
  itemsPerPage = 10,
  className,
}) => {
  const { t } = useTranslation();
  const { locale: currentLocale } = useLocalization();
  const industryTerms = useIndustryTerms(currentLocale);
  const jobTypeTerms = useParaworkJobTypeTerms(currentLocale);
  const { category, glossary, searchTerm, jobType } =
    useCompaniesParaworkFilters();
  const { currentPage } = usePager();
  const { data, isLoading } = useListingCompanyParaworkSearchQuery({
    offset: (currentPage - 1) * itemsPerPage,
    limit: itemsPerPage,
    glossary: glossary,
    text: searchTerm,
    industry: category ? [category] : [],
    language: currentLocale,
    jobType: jobType ? [jobType] : [],
  });

  const companies = data?.listingCompanyParaworkSearch?.items || [];
  const total = data?.listingCompanyParaworkSearch?.total || 0;

  const industryTermsMapped = industryTerms
    ? industryTerms.map(({ drupalId, title }) => ({
        id: drupalId!.toString(),
        label: title || '',
      }))
    : undefined;

  const jobTypeTermsMapped = jobTypeTerms
    ? jobTypeTerms.map(({ drupalId, title }) => ({
        id: drupalId!.toString(),
        label: title || '',
      }))
    : undefined;

  return (
    <div
      className={classNames(className, {
        'Pane is-loading relative': isLoading,
      })}
    >
      {isLoading && <Loader />}
      {
        <CompaniesParaworkFilterForm
          categories={industryTermsMapped}
          jobTypes={jobTypeTermsMapped}
        />
      }

      {companies && companies?.length ? (
        Object.entries(
          companies.reduce((groupedCompanies, company) => {
            if (!company) {
              return groupedCompanies;
            }
            const group = company.title.charAt(0).toUpperCase();
            if (!groupedCompanies[group]) {
              groupedCompanies[group] = [company as ContentCompany];
            } else {
              groupedCompanies[group] = [
                ...groupedCompanies[group],
                company as ContentCompany,
              ];
            }
            return groupedCompanies;
          }, {} as Record<string, ContentCompany[]>),
        ).map(([group, groupedCompanies]) => (
          <CompanyTeaserList
            key={`companies-${group}`}
            title={group}
            companies={groupedCompanies.map((company) => ({
              url: company.path,
              name: company.title,
              place: company.place,
              plz: company.zip,
              details: company.details,
              address: company.streetAndNumber,
              email: company.email,
              telephone: company.telephone,
              website: company.websiteLink?.url,
              paraworkJobType: company.paraworkJobType.filter(isTruthy),
              paraworkJobCompany: company.paraworkJobCompany,
              logo: getUrlFromStyles(
                company?.logo?.styles.filter(isTruthy),
                'thumbnail',
              ),
              hasContent: company.hasContent,
            }))}
          />
        ))
      ) : (
        <p className="ArticleText">
          {t('There are no companies that match your filters.')}
        </p>
      )}
      {total > itemsPerPage ? (
        <Pager itemsCount={total} itemsPerPage={itemsPerPage} />
      ) : null}
    </div>
  );
};

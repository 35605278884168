import { graphql, useStaticQuery } from 'gatsby';

export const useNewsTypesTermsQuery = () =>
  useStaticQuery<any>(graphql`
    query NewsTypesTerms {
      allDrupalTermNewsType {
        group(field: { langcode: SELECT }) {
          fieldValue
          nodes {
            drupalId
            title
            weight
          }
        }
      }
    }
  `);

import { Locale } from '../../i18n';
import { useDataDependencies, useFrameworkDependencies } from '../dependencies';
import { getNodesByLanguage } from '../utils';

export const useMedicalDomainTerms = (locale?: Locale) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const { useMedicalDomainTermsQuery } = useDataDependencies();
  const terms = useMedicalDomainTermsQuery();

  return getNodesByLanguage<
    MedicalDomainTermsQuery['allDrupalTermMedicalDomain']['group'][0]['nodes'][0]
  >(locale || currentLocale)(terms.allDrupalTermMedicalDomain);
};

import { ParagraphCallToAction } from '../../../generated/queries/preview-queries';

export const transformParagraphCallToActionToProps = (
  paragraph: ParagraphCallToAction,
) => ({
  url: paragraph.link?.url,
  title: paragraph.link?.title,
  target: paragraph.link?.options?.attributes?.target,
  buttonStyle: paragraph.buttonStyle,
});

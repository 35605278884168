import { ParagraphEventModule } from '../../../generated/queries/preview-queries';
import { isPublished } from '../../utils';

export const transformParagraphEventModuleToProps = (
  paragraph: ParagraphEventModule,
) => ({
  id: paragraph?.id || '',
  title: paragraph?.eventTitle,
  description: paragraph.descriptionHtml,
  prices:
    paragraph?.prices?.filter(isPublished).map((price) => ({
      label: price?.priceTitle || '',
      amount: price?.amount || 0,
    })) || [],
});

import {
  ContentZsmForm,
  ParagraphEventModule,
} from '../../../generated/queries/preview-queries';
import { ZSMFormPageProps } from '../../components/3-layouts';
import { isPublished } from '../../utils';

export const transformZSMFormFragmentToZSMFormPageProps = (
  webform?: ContentZsmForm,
): ZSMFormPageProps => {
  if (!webform) {
    return {};
  }

  return {
    title: webform.title,
    lead: webform?.leadHtml,
    modules: (
      webform?.modules as Array<ParagraphEventModule | undefined> | undefined
    )
      ?.filter(isPublished)
      .map((module) => ({
        id: module?.id || '',
        title: module?.eventTitle,
        description: module?.descriptionHtml,
        prices:
          module?.prices?.filter(isPublished).map((price) => ({
            label: price?.priceTitle || '',
            amount: price?.amount || 0,
          })) || [],
      })),
  };
};

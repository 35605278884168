import { graphql, useStaticQuery } from 'gatsby';

export const useEventCategoryTermsQuery = () =>
  useStaticQuery<EventCategoryTermsQuery>(graphql`
    query EventCategoryTerms {
      allDrupalTermEventCategory {
        group(field: { langcode: SELECT }) {
          fieldValue
          nodes {
            drupalId
            remoteId
            title
          }
        }
      }
    }
  `);

import { ParagraphDonationWidget } from '../../../generated/queries/preview-queries';

export const transformParagraphDonationWidgetToProps = (
  paragraph: ParagraphDonationWidget,
) => ({
  // @todo check why this was still referenced for JSON API
  //   as the field_donation_widget_url field does not exist on the backend.
  //scriptUrl: paragraph.field_donation_widget_url?.url || '',
  amounts: paragraph.amounts as Array<number>,
  thankYouUrl: paragraph.thankYouPageLink?.url,
  campaign: paragraph.campaignId ? parseInt(paragraph.campaignId) : undefined,
});

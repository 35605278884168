import React from 'react';
import { useTranslation } from 'react-i18next';

import { Event, Paragraph } from '../../types';
import {
  ParagraphCopyText,
  ParagraphKeyValueTable,
  ParagraphMapCoordinates,
} from '../0-atoms';
import { ParagraphAccordion } from './ParagraphAccordion';

export type EventsTeaserListProps = {
  events: Array<Event>;
  noEventsText: string;
};

export const EventsTeaserList: React.FC<EventsTeaserListProps> = ({
  events,
  noEventsText,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {events.length ? (
        <ParagraphAccordion
          items={events.map(
            ({ title, lead, info, map, image, url, domain }) => {
              const content: Paragraph[] = [];

              if (lead) {
                content.push(<ParagraphCopyText key="lead" text={lead} />);
              }

              if (info) {
                content.push(<ParagraphKeyValueTable key="info" rows={info} />);
              }

              if (map && map.latitude && map.longitude) {
                content.push(
                  <ParagraphMapCoordinates
                    key="map"
                    latitude={map.latitude}
                    longitude={map.longitude}
                    title={map.title}
                    small={map.small}
                  >
                    {map.text && (
                      <div dangerouslySetInnerHTML={{ __html: map.text }} />
                    )}
                  </ParagraphMapCoordinates>,
                );
              }

              return {
                type: 'link',
                title,
                image,
                url,
                domain,
                linkLabel: t('Read more'),
                content,
              };
            },
          )}
        />
      ) : (
        <p className="ArticleText">{noEventsText}</p>
      )}
    </>
  );
};

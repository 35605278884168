import React, { CSSProperties } from 'react';

import { ImageStyle } from '../../../generated/queries/preview-queries';
import { isNotSupportedMime } from '../../utils';

export type ImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  fit?: boolean;
  size?: 'small' | 'medium' | 'large';
  filemime?: string;
  styles?: Record<string, ImageStyle>;
  loading?: 'eager' | 'lazy';
};

const getSizes = (size: ImageProps['size']) => {
  switch (size) {
    case 'small':
      return '(max-width: 750px) 100vw, (max-width: 900px) 60vw, (max-width: 1050px) 42vw';
    case 'medium':
      return '(max-width: 750px) 100vw, (max-width: 900px) 80vw, (max-width: 1050px) 56vw';
    case 'large':
      return '(max-width: 750px) 100vw, (max-width: 900px) 100vw, (max-width: 1050px) 70vw';
    default:
      return undefined;
  }
};

const getSrcSet = (styles: ImageProps['styles']) =>
  styles
    ? Object.values(styles)
        .map((style) => {
          return `${style?.url} ${style?.width}w`;
        })
        .join(',')
    : undefined;

export const Image: React.FC<ImageProps> = ({
  fit = false,
  sizes,
  src,
  size,
  styles,
  alt,
  className,
  style,
  filemime,
  loading = 'lazy',
  ...props
}) => {
  const isGif = isNotSupportedMime(filemime, src);
  const imageStyle: CSSProperties | undefined = fit
    ? {
        ...style,
        ...{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        },
      }
    : style;
  return isGif ? (
    <img
      className={className}
      alt={alt}
      src={src}
      style={imageStyle}
      loading={loading}
    />
  ) : (
    <img
      alt={alt}
      sizes={sizes || getSizes(size)}
      srcSet={src || getSrcSet(styles)}
      src={
        src ||
        'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
      }
      className={className}
      style={imageStyle}
      loading={loading}
      {...props}
    />
  );
};

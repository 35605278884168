import classNames from 'classnames';
import React, { ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { A11y, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import { useDomain } from '../../hooks';
import { SwiperResizeObserverPlugin } from '../../plugins';
import {
  ParagraphHeaderSliderImage,
  ParagraphHeaderSliderImageProps,
  ParagraphHomeSliderItem,
  ParagraphHomeSliderItemProps,
} from '../0-atoms';

SwiperCore.use([Autoplay, Pagination, A11y, SwiperResizeObserverPlugin]);

export type ParagraphHeaderSliderItem =
  | ({ type: 'image' } & ParagraphHeaderSliderImageProps)
  | ({ type: 'video' } & ParagraphHomeSliderItemProps);

export type ParagraphHeaderSliderProps = {
  items: Array<ParagraphHeaderSliderItem>;
  id?: string;
  heroButton?: ReactNode;
  className?: string;
};

export const ParagraphHeaderSlider: React.FC<ParagraphHeaderSliderProps> = ({
  items,
  id,
  heroButton,
  className,
}) => {
  const { t } = useTranslation();
  const { domain } = useDomain();
  const paginationId = `pagination-${id}`;
  const swiper = useRef<SwiperClass>();

  useEffect(() => {
    const reInitPagination = () => {
      swiper.current?.pagination?.destroy();
      swiper.current?.pagination?.init();
      swiper.current?.pagination?.update();
    };
    window.addEventListener('load', reInitPagination);
    return () => window.removeEventListener('load', reInitPagination);
  }, []);

  return (
    <div className={classNames('HeaderSlider', className)}>
      <Swiper
        className="HeaderSlider--wrapper"
        wrapperTag="ul"
        slidesPerView={1}
        pagination={
          items.length > 1
            ? {
                el: `#${paginationId}`,
                bulletClass: 'Slider--pagination--bullet',
                bulletActiveClass: 'is-active',
                type: 'bullets',
                clickable: true,
              }
            : false
        }
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        updateOnWindowResize={typeof ResizeObserver !== undefined}
        onSwiper={(swiperInstance: SwiperClass) =>
          (swiper.current = swiperInstance)
        }
      >
        {items.map((item, index: number) => (
          <SwiperSlide
            className="HeaderSlider--slide"
            key={`teaser-${index}`}
            tag="li"
          >
            {item.type === 'image' && <ParagraphHeaderSliderImage {...item} />}
            {item.type === 'video' && <ParagraphHomeSliderItem {...item} />}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="HeaderSlider--footer">
        {!domain.hideContactInSidebar && (
          <div className="HeaderSlider--contactDetails">
            <div className="HeaderSlider--contact">
              <span className="visuallyhidden">
                {t('Contact {{domain}}', { domain: domain.name })}
              </span>
              <a
                className="HeaderSlider--contact--link"
                href={`tel:${domain.tel}`}
              >
                <span className="visuallyhidden">
                  {t('Phone number: {{phone}}', {
                    phone: domain.formattedTel,
                  })}
                </span>
                <i className="ico ico-phone-small" />
              </a>
              <a
                className="HeaderSlider--contact--link"
                href={`mailto:${domain.mail}`}
              >
                <span className="visuallyhidden">
                  {t('Mail: {{mail}}', { mail: domain.mail })}
                </span>
                <i className="ico ico-mail-small" />
              </a>
            </div>
          </div>
        )}
        <div className="HeaderSlider--pagination">
          <div id={paginationId} className="Slider--pagination" />
        </div>
        {heroButton && <div className="HeaderSlider--cta">{heroButton}</div>}
      </div>
    </div>
  );
};

export default ParagraphHeaderSlider;

import { format, parseISO } from 'date-fns';

import { ParagraphOrthotecValueTable } from '../../../generated/queries/preview-queries';

const dateFormatterDMY = (dateItem: string) => {
  const parsedDate = parseISO(dateItem);
  const dateDMY: String = format(parsedDate, 'd.M.Y');
  return dateDMY;
};

export const transformParagraphOrthotecKeyValueTableToProps = (
  paragraph: ParagraphOrthotecValueTable,
) => ({
  brand: paragraph.brand,
  modification: paragraph.modification,
  price: paragraph.price,
  firstCirculation: paragraph.firstCirculation,
  year: paragraph.year,
  km: paragraph.km,
  color: paragraph.color,
  inside: paragraph.inside,
  capacity: paragraph.capacity,
  ps: paragraph.ps,
  mfk: paragraph.mfkDate
    ? // Using toString() as expected by ParagraphOrthotecKeyValueTable props.
      dateFormatterDMY(paragraph.mfkDate).toString()
    : paragraph.mfkDate,
  priceNew: paragraph.newPrice,
  additionalEquipment: paragraph.additionalEquipment,
  options: paragraph.options,
  contact: paragraph.contact,
  phone: paragraph.phone,
  mobile: paragraph.mobile,
  fax: paragraph.fax,
  email: paragraph.email,
  published: paragraph.published,
});

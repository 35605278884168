import { Locale } from '../../i18n';
import { useDataDependencies, useFrameworkDependencies } from '../dependencies';
import { getNodesByLanguage } from '../utils';

export const useFieldOfExpertiseTerms = (locale?: Locale) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const { useFieldOfExpertiseTermsQuery } = useDataDependencies();
  const terms = useFieldOfExpertiseTermsQuery();

  return getNodesByLanguage<
    FieldOfExpertiseTermsQuery['allDrupalTermFieldOfExpertise']['group'][0]['nodes'][0]
  >(locale || currentLocale)(terms.allDrupalTermFieldOfExpertise);
};

import { graphql, useStaticQuery } from 'gatsby';

export const useOverlaysQuery = () =>
  useStaticQuery<OverlaysQuery>(graphql`
    query Overlays {
      allOverlays: allDrupalContentBreakingNews(
        filter: { showOverlay: { eq: true } }
      ) {
        group(field: { langcode: SELECT }) {
          fieldValue
          nodes {
            drupalId
            remoteId
            path
            domainAccess {
              id
            }
            overlayType
            overlayInterval
            overlayTitle
            overlaySubTitle
            overlayText
            overlayMoreText
            overlayMoreLink {
              title
              url
            }
            overlayImage {
              __typename
              alt
              height
              width
              title
              mimeType
              url
              styles {
                width
                height
                url
              }
            }
          }
        }
      }
    }
  `);

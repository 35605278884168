import {
  ParagraphAccordionDownloadItem,
  ParagraphAccordionLinkItem,
  ParagraphAccordionSimpleItem,
} from '../../../generated/queries/preview-queries';

export const isAccordionItemDownload = (
  item?:
    | ParagraphAccordionDownloadItem
    | ParagraphAccordionSimpleItem
    | ParagraphAccordionLinkItem,
): item is ParagraphAccordionDownloadItem =>
  item?.__typename === 'ParagraphAccordionDownloadItem';

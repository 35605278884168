import React, { ReactNode, useRef } from 'react';

import { useAccordion } from '../../hooks';
import { Icon, Paragraph } from '../../types';
import {
  AccordionButton,
  AccordionContent,
  AccordionTitle,
  AccordionToggle,
  AccordionWrapper,
  IconKey,
  IconSvg,
} from '../0-atoms';
import { Content } from './';

export type AccordionItemProps = {
  id: string;
  title?: string;
  subtitle?: string;
  url?: string;
  target?: string;
  domain?: string;
  image?: string | ReactNode;
  content?: Paragraph[];
  buttonLabel?: string;
  linkLabel?: string;
  isCollapsible?: boolean;
  hasLogoImage?: boolean;
  iconClass?: string;
  icon?: Icon;
  iconExpanded?: Icon;
};

const handleClick = (accordionItem: React.RefObject<HTMLLIElement>): void => {
  setTimeout(() => {
    const element = accordionItem.current;

    if (element) {
      const headerOffset = 140;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      element.focus();
    }
  }, 500);
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  id,
  title = '',
  subtitle = '',
  url,
  target,
  domain,
  image,
  content,
  buttonLabel = 'Toggle',
  linkLabel = 'Follow Link',
  isCollapsible = true,
  hasLogoImage = false,
  iconClass,
  icon,
  iconExpanded,
}) => {
  const accordionItem = useRef<HTMLLIElement>(null);
  const { activeAccordion, toggleAccordion } = useAccordion();

  // Used to determine whether the accordion holds an exclusive paragraph type only - apply helper classes
  const checkExclusivity = (): [boolean, boolean] => {
    if (content) {
      let isExclusive = true;
      let exclusivetype;

      for (let i = 0; i < content.length; i++) {
        const item = content[i];
        i == 0
          ? (exclusivetype = '__typename' in item && item.__typename)
          : (isExclusive =
              '__typename' in item && item.__typename === exclusivetype);
      }

      return [isExclusive, !!exclusivetype];
    }
    return [false, false];
  };

  const [isExclusive, exclusivetype] = checkExclusivity();
  const hasIconClass =
    typeof iconClass === 'string' && iconClass !== '_none' && iconClass !== '';

  return (
    <li
      data-exclusive={isExclusive}
      {...(isExclusive ? { exclusivetype: exclusivetype } : {})}
      ref={accordionItem}
      className="Accordion--item scroll-margin"
    >
      <AccordionWrapper
        isActive={activeAccordion === id}
        hasLogoImage={hasLogoImage || hasIconClass}
        isCollapsible={isCollapsible}
      >
        <div className="AccordionItem--toggleContainer">
          <AccordionToggle
            id={id}
            isActive={activeAccordion === id}
            isCollapsible={isCollapsible}
            toggleAccordion={() => {
              toggleAccordion(id);
              handleClick(accordionItem);
            }}
          >
            {(hasIconClass || image) && (
              <div className="AccordionItem--toggle--imgWrapper">
                {hasIconClass ? (
                  <div className="AccordionItem--icon">
                    <IconSvg
                      fill={'#fff'}
                      wrapClass={'AccordionItem--pack--icon'}
                      icon={`ico-${iconClass}` as IconKey}
                    />
                  </div>
                ) : null}
                {!hasIconClass && typeof image === 'string' ? (
                  <div
                    className="AccordionItem--toggle--img"
                    style={{
                      backgroundImage: `url(${image})`,
                    }}
                  />
                ) : (
                  image
                )}
              </div>
            )}
            <AccordionTitle title={title} subtitle={subtitle} />
          </AccordionToggle>
          <div className="AccordionItem--buttonWrapper">
            <AccordionButton
              id={id}
              isCollapsible={isCollapsible}
              label={buttonLabel}
              toggleAccordion={() => {
                toggleAccordion(id);
                handleClick(accordionItem);
              }}
            />
            {url && (
              <a
                className={`AccordionItem--button AccordionItem--button-cta AccordionItem--content--domain--${domain}`}
                href={url}
                target={target}
                rel="noreferrer"
              >
                <span className="visuallyhidden">{linkLabel}</span>
                {iconExpanded && (
                  <i
                    className={`AccordionItem--button--icon-opened ico ${iconExpanded}`}
                  />
                )}
                {icon && (
                  <i
                    className={`AccordionItem--button--icon-closed ico ${icon}`}
                  />
                )}
              </a>
            )}
          </div>
        </div>
        <AccordionContent
          id={id}
          isActive={activeAccordion === id}
          isCollapsible={isCollapsible}
        >
          <div className="AccordionItem--content--body">
            <Content className="inline" content={content} />
          </div>
          {url && (
            <div className="AccordionItem--content--footer">
              <a
                className={`AccordionItem--content--cta AccordionItem--content--domain--${domain}`}
                href={url}
                target={target}
                rel="noreferrer"
              >
                {linkLabel}
                {icon && <i className={`ico ${icon}`} />}
              </a>
            </div>
          )}
        </AccordionContent>
      </AccordionWrapper>
    </li>
  );
};

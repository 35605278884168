import { MenuId } from '../constants';
import { useFrameworkDependencies } from '../dependencies';
import { MenuItem } from '../types';
// @todo move it back in data dependencies for Storybook.
import { useSiteMenusQuery } from './useSiteMenusQuery';

function extractItemsByLocale<
  TInput extends Array<{ langcode: string }>,
  TKey extends TInput[number]['langcode'],
>(input: TInput, langcode: TKey): TInput[number] | undefined {
  return input.filter((item) => item.langcode === langcode).pop();
}

// @todo process maxDepth
export const useMenu = (id: MenuId): MenuItem[] => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();

  const result = useSiteMenusQuery();
  let menuTranslations;
  switch (id) {
    case 'fast-navigation-menu':
      menuTranslations = result?.drupalMenuFastNavigation?.translations;
      break;
    case 'footer-menu':
      menuTranslations = result?.drupalMenuFooter?.translations;
      break;
    case 'footer-quicklinks-activecom':
      menuTranslations =
        result?.drupalMenuFooterQuickLinksActiveCom?.translations;
      break;
    case 'footer-quicklinks-gv':
      menuTranslations =
        result?.drupalMenuFooterQuickLinksGonnerVereinigung?.translations;
      break;
    case 'footer-quicklinks-orthotec':
      menuTranslations =
        result?.drupalMenuFooterQuickLinksOrthotec?.translations;
      break;
    case 'footer-quicklinks-orthotecsports':
      menuTranslations =
        result?.drupalMenuFooterQuickLinksOrthotecSports?.translations;
      break;
    case 'footer-quicklinks-parahelp':
      menuTranslations =
        result?.drupalMenuFooterQuickLinksParahelp?.translations;
      break;
    case 'footer-quicklinks-sirmed':
      menuTranslations = result?.drupalMenuFooterQuickLinksSirmed?.translations;
      break;
    case 'footer-quicklinks-spf':
      menuTranslations = result?.drupalMenuFooterQuickLinksSpf?.translations;
      break;
    case 'footer-quicklinks-sportmedi':
      menuTranslations =
        result?.drupalMenuFooterQuickLinksSportMedizin?.translations;
      break;
    case 'footer-quicklinks-sps':
      menuTranslations = result?.drupalMenuFooterQuickLinksSps?.translations;
      break;
    case 'footer-quicklinks-spz':
      menuTranslations = result?.drupalMenuFooterQuickLinksSpz?.translations;
      break;
    case 'footer-social-menu':
      menuTranslations = result?.drupalMenuSocial?.translations;
      break;
    case 'footer-social-menu-activecom':
      menuTranslations = result?.drupalMenuSocialActiveCom?.translations;
      break;
    case 'footer-social-menu-orthotec':
      menuTranslations = result?.drupalMenuSocialOrthotec?.translations;
      break;
    case 'footer-social-menu-parahelp':
      menuTranslations = result?.drupalMenuSocialParahelp?.translations;
      break;
    case 'footer-social-menu-sirmed':
      menuTranslations = result?.drupalMenuSocialSirmed?.translations;
      break;
    case 'footer-social-menu-spf':
      menuTranslations = result?.drupalMenuSocialSpf?.translations;
      break;
    case 'footer-social-menu-sps':
      menuTranslations = result?.drupalMenuSocialSps?.translations;
      break;
    case 'footer-social-menu-spz':
      menuTranslations = result?.drupalMenuSocialSpz?.translations;
      break;
    case 'home-menu':
      menuTranslations = result?.drupalMenuHome?.translations;
      break;
    case 'main':
      menuTranslations = result?.drupalMenuMain?.translations;
      break;
    case 'meta-navigation':
      menuTranslations = result?.drupalMenuMetaNavigation?.translations;
      break;
    case 'meta-navigation-orthotecsports':
      menuTranslations =
        result?.drupalMenuMetaNavigationOrthotecSports?.translations;
      break;
    case 'sidebar-ac':
      menuTranslations =
        result?.drupalMenuSidebarActiveCommunication?.translations;
      break;
    case 'sidebar-gv':
      menuTranslations =
        result?.drupalMenuSidebarGonnerVereinigung?.translations;
      break;
    case 'sidebar-orthotec':
      menuTranslations = result?.drupalMenuSidebarOrthotec?.translations;
      break;
    case 'sidebar-orthotecsports':
      menuTranslations = result?.drupalMenuSidebarOrthotecSports?.translations;
      break;
    case 'sidebar-parahelp':
      menuTranslations = result?.drupalMenuSidebarParahelp?.translations;
      break;
    case 'sidebar-sirmed':
      menuTranslations = result?.drupalMenuSidebarSirmed?.translations;
      break;
    case 'sidebar-spf':
      menuTranslations = result?.drupalMenuSidebarSpf?.translations;
      break;
    case 'sidebar-sportmedizin':
      menuTranslations = result?.drupalMenuSidebarSportMedizin?.translations;
      break;
    case 'sidebar-sps':
      menuTranslations = result?.drupalMenuSidebarSps?.translations;
      break;
    case 'sidebar-spz':
      menuTranslations = result?.drupalMenuSidebarSpz?.translations;
      break;
  }

  const menuItems =
    (extractItemsByLocale(menuTranslations || [], locale)
      ?.items as DrupalMenuItem[]) || [];
  return buildMenuTree(menuItems);
};

export const buildMenuTree = (
  menu: Array<DrupalMenuItem> = [],
  parent: string = '',
): Array<MenuItem> =>
  menu
    .filter((item) => item.parent === parent)
    .map((item) => ({
      id: item.id,
      title: item.label,
      url: item.url,
      attributes: {
        title: item.titleAttribute,
        class: item.classAttribute,
        id: item.idAttribute,
        ariaControls: item.ariaControlsAttribute,
        ariaExpanded: item.ariaExpandedAttribute === 'true',
        ariaHasPopup: item.ariaHasPopupAttribute === 'true',
        accessKey: item.accessKeyAttribute,
        target: item.targetAttribute,
        enabled: item.isEnabled,
      },
      childItems: buildMenuTree(menu, item.id),
    }));

import React from 'react';

import { ContentThankYouPage } from '../../../generated/queries/preview-queries';
import { Image } from '../../components/0-atoms';
import { ThankYouPageProps } from '../../components/3-layouts';
import { getImageSrc } from '../../utils';
import { getImageStyleUrl } from './MediaImage';

export const transformThankYouPageFragmentToThankYouPageProps = (
  page?: ContentThankYouPage,
): ThankYouPageProps => {
  if (!page) {
    return {};
  }

  const thankYouImage = page?.image;

  const image = thankYouImage ? (
    <Image
      alt={thankYouImage?.alt || page.title}
      filemime={thankYouImage?.mimeType}
      src={getImageSrc(
        thankYouImage,
        `${getImageStyleUrl(thankYouImage, 'header_image')} 2000w, 
        ${getImageStyleUrl(
          thankYouImage,
          'header_slider_image_responsive_',
        )} 750w`,
      )}
      sizes="(max-width: 750px) 50vw, 100vw"
      className="SubpageHeader--background--img"
      fit
      loading="eager"
    />
  ) : undefined;

  return {
    title: page.title,
    subtitle: page.subTitle,
    body: page?.body,
    url: page.backLink?.url,
    linkLabel: page.backLink?.title,
    image,
    hideCtasHeader: page.hideCallToActionInHeader,
  };
};

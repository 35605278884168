import { graphql, useStaticQuery } from 'gatsby';

export const useMedicalDomainTermsQuery = () =>
  useStaticQuery<MedicalDomainTermsQuery>(graphql`
    query MedicalDomainTerms {
      allDrupalTermMedicalDomain {
        group(field: { langcode: SELECT }) {
          fieldValue
          nodes {
            drupalId
            remoteId
            title
          }
        }
      }
    }
  `);

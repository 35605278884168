import { graphql, useStaticQuery } from 'gatsby';

export const useBlogPaginationQuery = () =>
  useStaticQuery<any>(graphql`
    query BlogPagination {
      allDrupalContentBlog(sort: { created: DESC }) {
        edges {
          node {
            drupalId
            remoteId
            langcode
            title
            path
            domainAccess {
              id
            }
          }
        }
      }
    }
  `);

import { graphql, useStaticQuery } from 'gatsby';

export const useParaworkJobTypeTermsQuery = () =>
  useStaticQuery<ParaworkJobTypeTermsQuery>(graphql`
    query ParaworkJobTypeTerms {
      allDrupalTermParaworkJobType {
        group(field: { langcode: SELECT }) {
          fieldValue
          nodes {
            drupalId
            remoteId
            title
          }
        }
      }
    }
  `);

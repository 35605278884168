import { ParagraphWebform } from '../../../generated/queries/preview-queries';

export const transformParagraphWebformToProps = (
  paragraph: ParagraphWebform,
) => {
  const drupalUrl =
    process.env.GATSBY_BACKEND_BASE_URL || 'http://127.0.0.1:8888';
  const absoluteUrl = drupalUrl + paragraph.webformUrl;
  return {
    url: absoluteUrl,
    status: paragraph.status,
  };
};

import { Locale } from '../../i18n';
import { useDataDependencies, useFrameworkDependencies } from '../dependencies';
import { getNodesByLanguage } from '../utils';
import { useDomain } from './useDomain';

export const useOverlays = (locale?: Locale) => {
  const { domain } = useDomain();
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const { useOverlaysQuery } = useDataDependencies();
  const overlays = useOverlaysQuery();

  return getNodesByLanguage<
    OverlaysQuery['allOverlays']['group'][0]['nodes'][0]
  >(locale || currentLocale)(overlays.allOverlays)?.filter((overlay) =>
    overlay?.domainAccess?.some(
      (overlayDomain) => overlayDomain?.id === domain.id,
    ),
  );
};

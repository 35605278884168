import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';

import { useFrameworkDependencies } from '../dependencies';
import { getTranslatedUrl } from './getTranslatedUrl';

type CallToActionButton = {
  url: string;
  type: string;
};

export function useCallToAction(
  buttonLevel: string,
): CallToActionButton | undefined {
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const [callToActionButton, setCallToActionButton] =
    useState<CallToActionButton>();

  const settings = useStaticQuery(graphql`
    fragment DrupalLinkWithTranslations on DrupalLinkWithTranslations {
      translations {
        langcode
        link {
          url
        }
      }
    }
    query SettingsCallToAction {
      drupalSettingsCallToAction {
        primaryButtonType
        secondaryButtonType
        primaryButtonLink {
          ...DrupalLinkWithTranslations
        }
        secondaryButtonLink {
          ...DrupalLinkWithTranslations
        }
      }
    }
  `);

  useEffect(() => {
    switch (buttonLevel) {
      case 'primary':
        if (settings.drupalSettingsCallToAction.primaryButtonType) {
          const type = settings.drupalSettingsCallToAction.primaryButtonType;
          const url = getTranslatedUrl({
            urlObject:
              settings.drupalSettingsCallToAction?.primaryButtonLink
                ?.translations || [],
            langcode: currentLocale,
          }) as unknown as string;
          setCallToActionButton({
            type,
            url,
          });
        }
        break;
      case 'secondary':
        if (settings.drupalSettingsCallToAction.secondaryButtonType) {
          const type = settings.drupalSettingsCallToAction.secondaryButtonType;
          const url = getTranslatedUrl({
            urlObject:
              settings.drupalSettingsCallToAction?.secondaryButtonLink
                ?.translations || [],
            langcode: currentLocale,
          }) as unknown as string;
          setCallToActionButton({
            type,
            url,
          });
        }
        break;
    }
  }, [setCallToActionButton, buttonLevel]);

  return callToActionButton;
}

import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from '../../types';
import { FadeUp } from '../1-molecules/FadeIn';
import { ParagraphArticleTitle } from './ParagraphArticleTitle';
import { PersonTeaser } from './PersonTeaser';

export type ParagraphPersonTeaserProps = {
  title?: string;
  tag?: string;
  hasBackground?: boolean;
  persons: {
    name?: string;
    job?: string;
    email?: string;
    telephone?: string;
    fax?: string;
    image?: string | ReactNode;
    content?: Paragraph[];
  }[];
  className?: string;
};

export const ParagraphPersonTeaser: React.FC<ParagraphPersonTeaserProps> = ({
  title,
  tag,
  persons,
  className,
  hasBackground,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('PersonTeaserList', className, {
        'has-background': hasBackground,
        'has-title': title,
      })}
    >
      <FadeUp yGap={20}>
        {title && <ParagraphArticleTitle title={title} tag={tag} />}

        <ul className="PersonTeaserList--list">
          {persons.map((person, index) => {
            const personTelLink = 'tel:' + person.telephone;

            return (
              <li key={`person-${index}`}>
                <PersonTeaser
                  expandableLabel={t('Show CV')}
                  name={person.name}
                  image={person.image}
                  content={person.content}
                  className="PersonTeaser--card-style"
                >
                  {person.job && (
                    <>
                      <span dangerouslySetInnerHTML={{ __html: person.job }} />
                      <br />
                    </>
                  )}
                  {person.email && (
                    <>
                      <a
                        className="person--email"
                        href={`mailto:${person.email}`}
                      >
                        {person.email}
                      </a>
                      <br />
                    </>
                  )}
                  {person.telephone && (
                    <>
                      <span className="tel">
                        <span className="type">
                          <span aria-hidden="true">T.</span>
                          <span className="visuallyhidden">Phone</span>
                        </span>
                        &nbsp;
                        <a href={personTelLink}>{person.telephone}</a>
                      </span>
                      <br />
                    </>
                  )}
                  {person.fax && (
                    <>
                      <span className="fax">
                        <span className="type">
                          <span aria-hidden="true">F.</span>
                          <span className="visuallyhidden">Fax</span>
                        </span>
                        &nbsp;
                        {person.fax}
                      </span>
                      <br />
                    </>
                  )}
                </PersonTeaser>
              </li>
            );
          })}
        </ul>
      </FadeUp>
    </div>
  );
};

export default ParagraphPersonTeaser;

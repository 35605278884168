import React from 'react';

import { ContentThirdLevelPage } from '../../../generated/queries/preview-queries';
import { HeroButton } from '../../components/2-organisms';
import { ThirdLevelPageProps } from '../../components/3-layouts';

export const transformThirdLevelPageFragmentToThirdLevelPageProps = (
  page?: ContentThirdLevelPage,
): ThirdLevelPageProps => {
  if (!page) {
    return {};
  }

  const heroButton = page.heroButtonType ? (
    <HeroButton type={page.heroButtonType} url={page.heroButtonLink?.url} />
  ) : undefined;

  return {
    title: page.title,
    heroButton,
    hideFundingBanner: page.hideFundingBanner,
    hideCtasHeader: page.hideCallToActionInHeader,
  };
};

import classNames from 'classnames';
import { fromUnixTime } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListingNewsroomQuery } from '../../../generated/queries/react-queries';
import { Locale } from '../../../i18n';
import { getImageStyleUrl } from '../../fragments/mappers';
import { useDomain } from '../../hooks';
import { Domain } from '../../types';
import { formatLocalizedDate } from '../../utils';
import { IconKey, IconSvg } from '../0-atoms';
import { MasonryLink } from './MasonryItem';

export type NewsroomTeaserProps = NonNullable<
  NonNullable<ListingNewsroomQuery['listingNewsroom']['items']>[number]
> & {
  isHighlighted: boolean;
  hasImages?: boolean;
};

type HighlightedNewsroomTeaserProps = {
  url: string;
  title: string;
  formattedDate: string;
  newsroomDomain: Domain | undefined;
  image: string | undefined;
  newsType: string | undefined;
};

export const NewsroomTeaser: React.FC<NewsroomTeaserProps> = (props) => {
  const { getDomain } = useDomain();
  const newsroomDomain = getDomain(
    props.domainAccess[0]?.id ?? 'paraplegie_ch',
  );

  const newsroomDomainBodyClass = newsroomDomain?.bodyClass || undefined;
  const lang = props.langcode;
  const newsroomDomainLogo =
    lang && newsroomDomain?.logosvg ? newsroomDomain.logosvg[lang] : undefined;

  const { t } = useTranslation();

  const date =
    (props.__typename === 'ContentBlog' ? props.created : props.date) ??
    new Date();

  const formattedDate = formatLocalizedDate((props.langcode as Locale) ?? 'en')(
    isNaN(+date) ? new Date(date) : fromUnixTime(+date),
  );

  const image =
    props.__typename === 'ContentBlog' ? props.teaserImage : undefined;

  const imageSrc = image ? getImageStyleUrl(image ?? '', 'blog_teaser') : '';
  return (
    <>
      {props.isHighlighted ? (
        <HighlightedNewsroomTeaser
          title={props.title}
          url={props.path}
          image={imageSrc}
          formattedDate={formattedDate}
          newsroomDomain={newsroomDomain}
          newsType={props.newsType?.[0]?.title}
        />
      ) : (
        <MasonryLink
          path={props.path}
          className={classNames(
            'item NewsTeaser',
            {
              'NewsTeaser--WithImage': !!image,
              'NewsTeaser--WithoutImage': !image,
            },
            newsroomDomainBodyClass,
          )}
          gridItemGap={40}
          gridRowSize={4}
        >
          {image ? (
            <div className="NewsTeaser--ImgWrapper">
              <img
                alt={'Newsroom teaser image'}
                className="NewsTeaser--Img"
                src={getImageStyleUrl(image ?? '', 'blog_teaser')}
                loading="lazy"
              />
              <DomainInfo domain={newsroomDomain} />
            </div>
          ) : props.hasImages ? (
            <div
              className={classNames(
                'NewsTeaser--ImgWrapper NewsTeaser--Organisation-logo',
                newsroomDomainBodyClass,
              )}
            >
              <img
                alt={'Newsroom domain logo image'}
                className="NewsTeaser--Img"
                src={newsroomDomainLogo}
                loading="lazy"
              />
              <DomainInfo domain={newsroomDomain} />
            </div>
          ) : (
            <DomainInfo domain={newsroomDomain} />
          )}

          <div className="NewsTeaser--Content">
            <h3 className="NewsTeaser--Title">{t(props.title)}</h3>
            <p className="NewsTeaser--Date">
              {props.newsType?.[0]?.title && `${props.newsType?.[0]?.title} /`}{' '}
              {formattedDate}
            </p>
          </div>

          <IconSvg
            wrapClass={'NewsTeaser--Link'}
            icon={`ico-bolt-right` as IconKey}
            fill="#00417A"
          />
        </MasonryLink>
      )}
    </>
  );
};

export const HighlightedNewsroomTeaser: React.FC<
  HighlightedNewsroomTeaserProps
> = ({ url, title, formattedDate, newsroomDomain, image, newsType }) => {
  const { t } = useTranslation();

  return (
    <MasonryLink
      path={url}
      className={classNames('HighlightedNewsTeaser', newsroomDomain?.bodyClass)}
      gridItemGap={40}
      gridRowSize={4}
      fixedHeight={476}
    >
      {image && (
        <img
          className="HighlightedNewsTeaser--Img"
          alt={'Highlighted newsroom teaser image'}
          src={image}
        />
      )}
      <DomainInfo domain={newsroomDomain} isHighlighted={true} />

      <div className="HighlightedNewsTeaser--Content">
        <h3
          className={classNames(
            !image ? 'NewsTeaser--Title' : 'HighlightedNewsTeaser--Title',
          )}
        >
          {t(title)}
        </h3>
        <p
          className={classNames(
            !image ? 'NewsTeaser--Date' : 'HighlightedNewsTeaser--Date',
          )}
        >
          {newsType && `${newsType} /`} {formattedDate}
        </p>
      </div>
    </MasonryLink>
  );
};

export const DomainInfo: React.FC<{
  domain: Domain | undefined;
  hideTitle?: boolean;
  isHighlighted?: boolean;
}> = ({ domain, isHighlighted, hideTitle }) => {
  const { t } = useTranslation();

  // according to classes defined in  _colors.scss
  const domainTheme = domain?.bodyClass?.split('theme-')[1];
  const domainBackgroundColor = `theme-${domainTheme}`;

  return !isHighlighted ? (
    <div
      className={classNames(
        hideTitle
          ? 'NewsTeaser--Domain NewsTeaser--Domain--Solid'
          : 'NewsTeaser--Domain',
        domainBackgroundColor,
      )}
    >
      <div
        className={classNames(
          domainBackgroundColor,
          'NewsTeaser--Domain--Line',
        )}
      />
      {!hideTitle && (
        <div
          className={classNames(
            domainBackgroundColor,
            'NewsTeaser--Domain--TitleWrapper',
          )}
        >
          <p className="NewsTeaser--Domain--Title">{t(domain?.title)}</p>
        </div>
      )}
    </div>
  ) : (
    <>
      <div
        className={classNames(
          domainBackgroundColor,
          'NewsTeaser--Domain--Line',
          domainBackgroundColor,
        )}
      />
      <div
        className={classNames(
          domainBackgroundColor,
          'NewsTeaser--Domain--TitleWrapper',
        )}
      >
        <p className="NewsTeaser--Domain--Title">{t(domain?.title)}</p>
      </div>
    </>
  );
};

import { Locale } from '../../i18n';
import { useDataDependencies, useFrameworkDependencies } from '../dependencies';
import { getNodesByLanguage } from '../utils';

export const useParaworkJobTypeTerms = (locale?: Locale) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const { useParaworkJobTypeTermsQuery } = useDataDependencies();
  const terms = useParaworkJobTypeTermsQuery();
  return getNodesByLanguage<
    ParaworkJobTypeTermsQuery['allDrupalTermParaworkJobType']['group'][0]['nodes'][0]
  >(locale || currentLocale)(terms.allDrupalTermParaworkJobType);
};

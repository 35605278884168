import { createDependencyContext } from '@amazeelabs/react-di';
import { NavigateOptions, useLocation } from '@reach/router';

import { LocalizedLink, SEO } from './components/0-atoms';
import {
  useEntityUrlsQuery,
  useLocalization,
  usePageTranslations,
} from './hooks';

export type FrameworkDependencies = {
  Link: typeof LocalizedLink;
  navigate: (to: string, options?: NavigateOptions<{}>) => Promise<void>;
  SEO: typeof SEO;
  useLocation: typeof useLocation;
  useLocalization: typeof useLocalization;
  usePageTranslations: typeof usePageTranslations;
};

export const [
  FrameworkDependencyProvider,
  FrameworkDependencyOverride,
  useFrameworkDependencies,
] = createDependencyContext<FrameworkDependencies>();

export type DataDependencies = {
  useNewsTypesTermsQuery: () => NewsTypesTermsQuery;
  useBlogPaginationQuery: () => BlogPaginationQuery;
  useBlogOrganisationTermsQuery: () => BlogOrganisationTermsQuery;
  useCategoryTermsQuery: () => CategoryTermsQuery;
  useEntityUrlsQuery: (
    params: Parameters<typeof useEntityUrlsQuery>[0],
  ) => Pick<ReturnType<typeof useEntityUrlsQuery>, 'data' | 'isLoading'>;
  useEventCategoryTermsQuery: () => EventCategoryTermsQuery;
  useFieldOfExpertiseTermsQuery: () => FieldOfExpertiseTermsQuery;
  useIndustryTermsQuery: () => IndustryTermsQuery;
  useParaworkJobTypeTermsQuery: () => ParaworkJobTypeTermsQuery;
  useLatestEventsQuery: () => LatestEventsQuery;
  useMedicalDomainTermsQuery: () => MedicalDomainTermsQuery;
  useOverlaysQuery: () => OverlaysQuery;
  useSiteMenusQuery: () => AllMenusQuery;
  useSiteMetadataQuery: () => SiteMetadataQuery;
};

export const [
  DataDependencyProvider,
  DataDependencyOverride,
  useDataDependencies,
] = createDependencyContext<DataDependencies>();

import React from 'react';

import { ParagraphSlider } from '../../../generated/queries/preview-queries';
import { Image } from '../../components/0-atoms';
import { ParagraphHeaderSliderProps } from '../../components/2-organisms';
import { getImageSrc, isPublished } from '../../utils';
import { getImageStyleUrl } from './MediaImage';
import { isHeaderSliderImage } from './ParagraphSliderImage';

export const transformParagraphHeaderSliderToProps = (
  paragraph: ParagraphSlider,
): ParagraphHeaderSliderProps => {
  return {
    id: paragraph.id,
    items:
      paragraph?.items?.filter(isPublished).map((item) => {
        const paragraphImage = item?.image;

        const desktopWideStyleUrl = getImageStyleUrl(
          paragraphImage,
          'desktop_wide',
        );
        const isCropped = desktopWideStyleUrl !== null;

        const image = paragraphImage ? (
          <Image
            className="HeaderSliderSlide--img"
            filemime={paragraphImage?.mimeType}
            alt={paragraphImage?.alt}
            title={paragraphImage?.title}
            sizes="(max-width: 750px) 50vw, 100vw"
            src={
              isCropped
                ? getImageSrc(
                    paragraphImage,
                    `${desktopWideStyleUrl} 2000w,
                    ${getImageStyleUrl(
                      paragraphImage,
                      'desktop_standard',
                    )} 1440w,
                    ${getImageStyleUrl(paragraphImage, 'mobile')} 750w`,
                  )
                : getImageSrc(
                    paragraphImage,
                    `${getImageStyleUrl(paragraphImage, 'header_slider')} 2000w,
                    ${getImageStyleUrl(
                      paragraphImage,
                      'header_slider_image_responsive_',
                    )} 750w`,
                  )
            }
            fit
            loading="eager"
          />
        ) : undefined;

        if (item && isHeaderSliderImage(item)) {
          return {
            type: 'image',
            image,
            title: item?.imageTitle,
            titleTag: item?.headingLevel?.replace(/^<|>$/g, ''),
            description: item?.descriptionHtml,
            url: item?.link?.url,
            target: item?.link?.options?.attributes?.target,
          };
        } else {
          const paragraphMobileImage = item?.mobileImage;
          const mobileImage = paragraphMobileImage ? (
            <Image
              className="HeaderSliderSlide--background--img mobile"
              filemime={paragraphMobileImage?.mimeType}
              alt={paragraphMobileImage?.alt}
              title={paragraphMobileImage?.title}
              src={getImageSrc(
                paragraphMobileImage,
                getImageStyleUrl(paragraphMobileImage, 'mobile'),
              )}
              fit
              loading="eager"
            />
          ) : undefined;

          return {
            type: 'video',
            image,
            mobileImage,
            video: item?.video?.url,
            mobileVideo: item?.mobileVideo?.url,
            title: item?.itemTitle,
            description: item?.descriptionHtml,
            url: item?.link?.url,
            target: item?.link?.options?.attributes?.target,
          };
        }
      }) || [],
  };
};

import { ParagraphDownloadList } from '../../../generated/queries/preview-queries';
import { formatSize, isPublished } from '../../utils';

export const transformParagraphDownloadListToProps = (
  paragraph: ParagraphDownloadList,
) => ({
  downloads:
    paragraph?.files?.filter(isPublished).map((download) => ({
      url: download?.url,
      label: download?.name,
      size: download?.size ? formatSize(download?.size) : undefined,
      type: download?.mimeType,
    })) || [],
});

import { ParagraphMap } from '../../../generated/queries/preview-queries';

export const transformParagraphMapCoordinatesToProps = (
  paragraph: ParagraphMap,
) => ({
  latitude: parseFloat(paragraph.latitude!),
  longitude: parseFloat(paragraph.longitude!),
  title: paragraph?.mapTitle,
  small: paragraph.smallVariant,
});

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { buildUrl } from '../../utils';

export const FooterNewsletter: React.FC = () => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { t } = useTranslation();
  // PROD hotfix only.
  // See https://amazeelabs.slack.com/archives/C05NB79EHJR/p1703082287676519
  const backendUrl = 'https://backend.paraplegie.ch';

  return (
    <form
      action={buildUrl([backendUrl, locale, 'newsletter-sign-up'])}
      method="GET"
      className="FooterNewsletter"
    >
      <input type="hidden" name="form" value="20" />
      <div className="FooterNewsletter--inner">
        <div className="visuallyhidden">
          {t('Für Newsletter der Paraplegiker Stiftung anmelden')}
        </div>
        <div className="FooterNewsletter--inputWrapper">
          <label className="visuallyhidden">{t('Email *')}</label>
          <input
            type="email"
            name="email"
            className="FooterNewsletter--input"
            placeholder={t('Email address')}
            required
          />
        </div>
        <div className="FooterNewsletter--buttonWrapper">
          <button className="FooterNewsletter--button" type="submit">
            {t('Subscribe')}
          </button>
        </div>
      </div>
    </form>
  );
};

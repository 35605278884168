import { graphql, useStaticQuery } from 'gatsby';

export const useBlogOrganisationTermsQuery = () =>
  useStaticQuery<any>(graphql`
    query BlogOrganisationTerms {
      allDrupalTermBlogOrganisation {
        group(field: { langcode: SELECT }) {
          fieldValue
          nodes {
            drupalId
            remoteId
            title
            path
          }
        }
      }
    }
  `);

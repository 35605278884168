import React from 'react';

import { ParagraphTeasers } from '../../../generated/queries/preview-queries';
import { Image } from '../../components/0-atoms';
import { getImageSrc, isPublished } from '../../utils';
import { getImageStyleUrl, transformImageFragmentToProps } from './MediaImage';

export const transformParagraphTeasersToProps = (
  paragraph: ParagraphTeasers,
) => {
  const paragraphImage = paragraph.firstRow?.image;
  const image = paragraphImage ? (
    <Image
      {...transformImageFragmentToProps(paragraphImage, [
        'teaser',
        'teaser_large',
      ])}
      filemime={paragraphImage?.mimeType}
      src={getImageSrc(
        paragraphImage,
        `${getImageStyleUrl(paragraphImage, 'teaser')} 1x,
        ${getImageStyleUrl(paragraphImage, 'teaser_large')} 2x`,
      )}
      className="ContentTeaser--backgroundImg"
    />
  ) : undefined;

  const firstRow = {
    title: paragraph?.firstRow?.link?.title,
    url: paragraph?.firstRow?.link?.url,
    target: paragraph?.firstRow?.link?.options?.attributes?.target,
    text: paragraph?.firstRow?.textHtml,
    image,
  };

  const secondRow = paragraph?.secondRow?.filter(isPublished).map((item) => ({
    title: item?.link?.title,
    linkLabel: item?.link?.title,
    url: item?.link?.url,
    target: item?.link?.options?.attributes?.target,
    text: item?.textHtml,
    category: item?.category?.title,
    iconName: item?.iconClass,
  }));

  return {
    firstRow: image ? firstRow : undefined,
    secondRow: secondRow,
  };
};

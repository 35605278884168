import { Locale } from '../../i18n';
import { useDataDependencies, useFrameworkDependencies } from '../dependencies';
import { getNodesByLanguage } from '../utils';

export const useBlogOrganisationTerms = (locale?: Locale) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const { useBlogOrganisationTermsQuery } = useDataDependencies();
  const organisationTerms = useBlogOrganisationTermsQuery();

  return getNodesByLanguage<
    BlogOrganisationTermsQuery['allDrupalTermBlogOrganisation']['group'][0]['nodes'][0]
  >(locale || currentLocale)(organisationTerms.allDrupalTermBlogOrganisation);
};

import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMenusQuery = () =>
  useStaticQuery<AllMenusQuery>(graphql`
    query AllMenus {
      drupalMenuFastNavigation {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuFooter {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuFooterQuickLinksActiveCom {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuFooterQuickLinksGonnerVereinigung {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuFooterQuickLinksOrthotec {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuFooterQuickLinksOrthotecSports {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuFooterQuickLinksParahelp {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuFooterQuickLinksSirmed {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuFooterQuickLinksSpf {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuFooterQuickLinksSportMedizin {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuFooterQuickLinksSps {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuFooterQuickLinksSpz {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSocial {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSocialActiveCom {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSocialParahelp {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSocialSirmed {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSocialOrthotec {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSocialSpf {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSocialSps {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSocialSpz {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuHome {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuMain {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuMetaNavigation {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuMetaNavigationOrthotecSports {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSidebarActiveCommunication {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSidebarGonnerVereinigung {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSidebarOrthotec {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSidebarOrthotecSports {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSidebarParahelp {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSidebarSirmed {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSidebarSpf {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSidebarSportMedizin {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSidebarSps {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
      drupalMenuSidebarSpz {
        translations {
          langcode
          items {
            ...MenuItem
          }
        }
      }
    }
  `);
